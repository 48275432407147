import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#d46e5a",
    },
    secondary: {
      main: "#246071",
    },
    success: {
      main: "#00AA51",
    },
    text: {
      primary: "#5e5e5e",
      secondary: "#284b54",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },

    error: {
      main: "#ab0000",
    },
    info: {
      main: "#246071",
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Nunito Sans, sans-serif",
    h1: {
      fontWeight: 400,
      fontFamily: "Georgia",
    },
    h3: {
      fontWeight: 400,
      fontFamily: "Georgia",
    },
    h2: {
      fontWeight: 400,
      fontFamily: "Georgia",
    },
    h4: {
      fontFamily: "Georgia",
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      fontFamily: "Georgia",
      letterSpacing: "2.4px",
    },
    h6: {
      fontSize: 12,
      letterSpacing: "2.4px",
      fontFamily: "Georgia",
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontWeight: 400,
      letterSpacing: "0.00938em",
    },
  },

  overrides: {
    MuiInput: {
      input: {
        borderRadius: "0px",
      },
    },
    MuiChip: {
      root: {
        borderRadius: "8px",
        height: "22px",
      },
      label: {
        color: "#FFF",
      },
    },
  },
});

export const STRIPE_THEME = {
  theme: "stripe",

  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#5e5e5e",
    colorDanger: "#df1b41",
    fontFamily: "Nunito Sans, sans-serif",
    spacingUnit: "4px",
    borderRadius: "0px",
    // See all possible variables below
  },
  rules: {
    ".Label": {
      textTransform: "uppercase",
      letterSpacing: "2px",
      fontWeight: "400",
      fontSize: "10px",
    },
    ".Input": {
      borderRadius: "0px",
      color: "#5E5E5E",
    },
    ".Input--empty::placeholder": {
      color: "#CCCCCC",
    },
  },
};
