import React from "react";

import Text from "components/Text";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

import { SMALL } from "constants/breakpoints.js";
import { useMediaQuery } from "react-responsive";
import { useProfile } from "hooks";
import UpgradePlanNotification from "components/Notifications/UpgradePlanNotification/UpgradePlanNotification";

import styles from "./PageHeader.module.scss";

function PageHeader({ title, children }) {
  const profile = useProfile();
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  // const { needsUpgrade } = useProfile();

  return (
    <>
      <Grid
        container
        className={`${!isMobile && styles.pageHeaderContainer} ${
          isMobile && styles.minimumnPageHeaderContainer
        } 
      ${isMobile && styles.dropShadow}
      `}
      >
        {!isMobile && profile.isOverLimit() && <UpgradePlanNotification />}
        <Text className="text__page-header">{title}</Text>
        <div className={styles.actionContainer}>{children}</div>
      </Grid>
      {isMobile && profile.isOverLimit() && <UpgradePlanNotification />}
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  title: "",
};

export default PageHeader;
