import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import CircularProgress from "@mui/material/CircularProgress";

import styles from "../Switch.module.scss";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#d56f5b" : "#d56f5b",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#d56f5b" : "#d56f5b",
    boxSizing: "border-box",
  },
}));

export default function PlanSwitch({ handleChange, checked, loading }) {
  return (
    <div>
      {loading ? (
        <CircularProgress
          size={22}
          color="secondary"
          className={styles.progress}
        />
      ) : (
        <AntSwitch checked={checked} onChange={handleChange} />
      )}
    </div>
  );
}
