import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { TRY_FOR_FREE, SHOP_ONBOARDING_SHOPIFY } from "constants/constants";
// step A:

import OnboardingStepTwo from "views/Onboarding/OnboardingStepTwo";
import OnboardingStepThree from "views/Onboarding/OnboardingStepThree";
import OnboardingStepFour from "views/Onboarding/OnboardingStepFour";
import OnboardingStepFive from "views/Onboarding/OnboardingStepFive";
// step B:
import OnboardingStepTwoB from "views/OnboardingB/OnboardingStepTwoB";
import OnboardingStepThreeB from "views/OnboardingB/OnboardingStepThreeB";
import OnboardingStepFourB from "views/OnboardingB/OnboardingStepFourB";
import OnboardingStepFiveB from "views/OnboardingB/OnboardingStepFiveB";

import ShopifyOnboardingStepOne from "views/ShopifyOnboardingView/ShopifyOnboardingStepOne";
import ShopifyOnboardingStepTwo from "views/ShopifyOnboardingView/ShopifyOnboardingStepTwo";

import { TEST_ONBOARDING_VERSION_B } from "constants/ab_tests";

export default function OnboardingView() {
  const shop = useSelector((state) => state.profile.shop);
  const profile = useSelector((state) => state.profile.profile);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("onboarding", shop.onboarding_step);
    window.history.pushState("", "", `/?${urlParams.toString()}`);
  }, [shop]);

  if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
    const ONBOARDING_STEP = {
      1: <ShopifyOnboardingStepOne />,
      2: <ShopifyOnboardingStepTwo />,
    };
    return <div>{ONBOARDING_STEP[shop.onboarding_step]}</div>;
  }

  const ONBOARDING_STEP = {
    1: <OnboardingStepTwo />,
    2: <OnboardingStepThree />,
    3: <OnboardingStepFour />,
    4: <OnboardingStepFive />,
  };

  const ONBOARDING_STEP_B = {
    1: <OnboardingStepTwoB />,
    2: <OnboardingStepThreeB />,
    3: <OnboardingStepFourB />,
    4: <OnboardingStepFiveB />,
  };

  return (
    <div>
      <Helmet>
        <title>Welcome - Blanka</title>
        <meta
          name="description"
          content="Official app login page app for Shopify, Wix, WooCommerce and more"
        />
      </Helmet>
      {ONBOARDING_STEP_B[shop.onboarding_step]}
    </div>
  );
}
