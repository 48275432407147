import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import { postCreateCustomProducts, updateShop } from "api";
import { GenericSuccessNotification } from "components/Notifications";
import { PLAN_FREE_ID } from "constants/plans";
import { useAnalytics } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteLogo,
  nextInnerStep,
  nextOuterStep,
  prevInnerStep,
  prevOuterStep,
  setActiveInnerStep,
  setActiveOuterStep,
  setIsGeneratorCompleted,
  setIsGeneratorStarted,
  setIsLogoUploaded,
  setIsOpen,
  setLogo,
} from "state/myLogoSlice";
import { setShop } from "state/profileSlice";

import { SMALL } from "constants/breakpoints.js";
import { useAbTests } from "hooks/useABTests";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { steps } from "constants/generatorLogo.js";

import {
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  CUSTOM_PRODUCT_STATUS_NONE,
} from "constants/constants.js";

import MyLogoMobileStepper from "../MyLogoMobileStepper/MyLogoMobileStepper";

import MyLogoStepFive from "../MyLogoSteps/MyLogoStepFive";
import MyLogoStepFour from "../MyLogoSteps/MyLogoStepFour";
import MyLogoStepOne from "../MyLogoSteps/MyLogoStepOne";
import MyLogoStepSix from "../MyLogoSteps/MyLogoStepSix";
import MyLogoStepThree from "../MyLogoSteps/MyLogoStepThree";
import MyLogoStepTwo from "../MyLogoSteps/MyLogoStepTwo";
import MyLogoStepZero from "../MyLogoSteps/MyLogoStepZero";

import MobileStepper from "../MyLogoStepper/MobileStepper/MobileStepper";

import styles from "./MyLogoStepperProgress.module.scss";

function MyLogoStepperProgress(props) {
  const { handleClose, handlePaywallOpen } = props;
  const analytics = useAnalytics();
  const [, setIsLoading] = useState(false);
  const abTests = useAbTests();

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    activeOuterStep,
    activeInnerStep,
    isGeneratorStarted,
    isLogoUploaded,
    isGeneratorCompleted,
  } = useSelector((state) => state.myLogo);

  const { logos } = useSelector((state) => state.myLogo);
  const shop = useSelector((state) => state.profile.shop);

  useEffect(() => {
    analytics.sendEvent(analytics.EVENT_CHANGE_MY_LOGO_STEP, {
      step: activeOuterStep,
    });
  }, [activeOuterStep]);

  const handleGetStarted = async () => {
    try {
      // await postCreateCustomProducts();
      dispatch(setIsGeneratorStarted(true));
      dispatch(setActiveOuterStep(0));
      dispatch(setLogo([]));
    } catch (error) {
      // handle error
      // console.log(error);
    }
  };

  const handleResetGenerator = async () => {
    try {
      // Take the user back to the first step:
      dispatch(setIsGeneratorStarted(false));
      dispatch(setIsGeneratorCompleted(false));
      dispatch(setIsLogoUploaded(false));
      dispatch(setActiveOuterStep(-1));
      dispatch(setActiveInnerStep(0));

      dispatch(setLogo([]));

      // if the product was already created custom_product_status will be set to NONE
      // and the brand_plate_created will be set to false:
      if (shop?.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE) {
        const updatedShop = {
          custom_product_status: CUSTOM_PRODUCT_STATUS_NONE,
          brand_plate_created: false,
        };
        await updateShop(shop?.id, updatedShop);
      }
    } catch (error) {
      // handle error
      // console.log(error);
    }
  };

  const handleNext = async () => {
    const currentInnerStepsLength = steps[activeOuterStep].innerSteps.length;
    // If the activeInnerStep is the last inner step, then go to the next outer step
    if (activeInnerStep === currentInnerStepsLength - 1) {
      dispatch(nextOuterStep({ innerStepsLength: currentInnerStepsLength }));
      // Resets the activeInnerStep to 0 because we are going to the next outer step
      dispatch(setActiveInnerStep(0));
    } else {
      dispatch(nextInnerStep({ innerStepsLength: currentInnerStepsLength }));
    }
  };

  const handlePrevious = () => {
    // If the activeInnerStep is the first inner step, then go to the previous outer step
    const prevOuterStepIndex = activeOuterStep - 1;
    const isAtFirstInnerStep = activeInnerStep === 0;
    const isAtFirstOuterStep = prevOuterStepIndex < 0;

    if (isAtFirstOuterStep && !isLogoUploaded) {
      // Those resets guarantee we can go back to the step zero:
      dispatch(setActiveOuterStep(-1));
      dispatch(setActiveInnerStep(0));
      dispatch(setIsLogoUploaded(false));
      dispatch(setIsGeneratorStarted(false));
      // [LOGO_DEFAULT].map((logoType) => dispatch(deleteLogo(logoType)));
      dispatch(setLogo([]));
    } else if (isAtFirstOuterStep) {
      dispatch(setActiveOuterStep(0));
      dispatch(setIsLogoUploaded(false));
      logos.map((logoType) => dispatch(deleteLogo(logoType)));
    } else if (isAtFirstInnerStep) {
      const prevInnerStepsLength =
        steps[prevOuterStepIndex]?.innerSteps?.length || 0;
      dispatch(prevOuterStep({ prevInnerStepsLength }));
    } else if (typeof activeInnerStep === "number" && activeInnerStep >= 0) {
      dispatch(prevInnerStep());
    }
  };

  const handleConfirmLogoGenerated = async () => {
    setIsLoading(true);
    try {
      await postCreateCustomProducts();
      // set shop to IN_PROGRESS
      const updatedShop = {
        ...shop, // Assuming you have the current shop state available
        custom_product_status: CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
      };

      dispatch(setShop(updatedShop));

      dispatch(setIsGeneratorStarted(false));
      dispatch(setIsOpen(false));
      dispatch(setIsGeneratorCompleted(true));
      setIsLoading(false);
      navigate("/");

      analytics.sendEvent(analytics.EVENT_MY_LOGO_COMPLETE);
      toast.success(
        <GenericSuccessNotification
          title="Hang tight!"
          text={`We're creating your custom product images. You'll
        receive an email when they are complete.`}
        />
      );
    } catch (error) {
      dispatch(setIsOpen(false));
      setIsLoading(false);
    }
  };

  const handleMyLogoSteps = (currentActiveStep, currentInnerStep) => {
    if (isGeneratorCompleted) {
      return (
        <MyLogoStepSix
          handleResetGenerator={handleResetGenerator}
          handleConfirmLogoGenerated={handleConfirmLogoGenerated}
          handlePrevious={handlePrevious}
        />
      );
    }

    if (!isGeneratorStarted && currentActiveStep === -1) {
      return <MyLogoStepZero handleGetStarted={handleGetStarted} />;
    }

    if (currentActiveStep === 0 && currentInnerStep === 0) {
      if (isLogoUploaded) {
        return (
          <MyLogoStepTwo
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
      }

      return <MyLogoStepOne handlePrevious={handlePrevious} />;
    }

    if (currentActiveStep === 1) {
      switch (currentInnerStep) {
        case 0:
          return (
            <MyLogoStepThree
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          );
        case 1:
          return (
            <MyLogoStepFour
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          );
        case 2:
          return (
            <MyLogoStepFive
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          );
        default:
          return (
            <MyLogoStepThree
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          );
      }
    }

    return (
      <MyLogoStepSix
        handleResetGenerator={handleResetGenerator}
        handleConfirmLogoGenerated={handleConfirmLogoGenerated}
        handlePrevious={handlePrevious}
      />
    );
  };

  return (
    <Box className={styles.myLogoStepperProgress}>
      {!isMobile && (
        <>
          <Typography
            onClick={handleClose}
            className={`${styles.modalCloseButton} close__button`}
          >
            [x] close
          </Typography>
          {handleMyLogoSteps(activeOuterStep, activeInnerStep)}
        </>
      )}
      {isMobile && (
        <MyLogoMobileStepper
          handlePrevious={handlePrevious}
          handleClose={handleClose}
        >
          {isGeneratorStarted && (
            <Box item className={styles.stepper}>
              <MobileStepper steps={steps} />
            </Box>
          )}
          {handleMyLogoSteps(activeOuterStep, activeInnerStep)}
        </MyLogoMobileStepper>
      )}
    </Box>
  );
}

MyLogoStepperProgress.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

MyLogoStepperProgress.defaultProps = {};

export default MyLogoStepperProgress;
