import PropTypes from "prop-types";
import React from "react";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";

import styles from "./ModalWrapper.module.scss";

export default function ModalWrapper(props) {
  const { handleClose, children, isOpen, className, title, headerType } = props;

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
      aria-labelledby="Modal Wrapper"
      aria-describedby="Modal Wrapper"
      data-testid="ModalWrapper"
      data-cy="modal"
      // onBackdropClick={handleClose}
      onClose={handleClose}
    >
      <Slide
        direction="up"
        timeout={300}
        in={isOpen}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={`modal-wrapper-content ${styles.modalWrapper} ${className}`}
        >
          {headerType !== "mobile" ? (
            <Typography
              data-cy="close-modal-button"
              onClick={() => onClose()}
              className={`${styles.modalCloseButton} close__button`}
            >
              [x] close
            </Typography>
          ) : (
            <Typography
              className={`${styles.modalCloseButton} ${styles.productDetailHeader} ${styles.filterMenuBoxShadow} close__button`}
            >
              <KeyboardBackspaceIcon
                onClick={() => onClose()}
                className={styles.placeIcon}
              />
              <span className={styles.title}>{title}</span>
            </Typography>
          )}
          {children}
        </div>
      </Slide>
    </Modal>
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

ModalWrapper.defaultProps = {
  isOpen: false,
  className: "",
};
