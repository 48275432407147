import React, { useState, useEffect } from "react";

// third party libraries
import moment from "moment";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

// MUI components:
import { Box, Button, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Custom components & assets:
import Text from "components/Text";
import { FREE_PLAN_ID, PLAN_PRICING_TABLE_INFO } from "constants/plans";

import ChangePlanModal from "components/Modals/ChangePlanModal";
import { useProfile } from "hooks";
import PlanDescription from "../PlanDescription";

import styles from "./Review.module.scss";

function Review(props) {
  const { selectedPlan, planPair, handlePlanSelection, changePlan } = props;
  const shop = useSelector((state) => state.profile.shop);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const isAnnualSelected = selectedPlan === planPair.annual;

  // make sure to redirect users that try to access the urls directly
  useEffect(() => {
    if (!shop.plan || !changePlan.trackPlan.new_plan) {
      navigate("/profile");
    }
  }, []);

  const profileHook = useProfile();

  const handleClose = () => {
    setOpen(false);
    // dispatch(setDowngradeStates(false));
    navigate("/downgrade/review");
  };

  // prevent users from accessing the page directly
  if (!changePlan.choosenPlan) {
    return <div />;
  }

  return (
    <Box className={styles.review}>
      <Text className={styles.title}>Review changes</Text>
      <Text className={styles.subTitle}>
        After you confirm the change, it will be reflected in your account at
        the end of your billing cycle (
        {moment(shop?.subscription_next_billing).format("MMMM Do YYYY")}). Your
        plan will change from
        <strong>
          {" "}
          {
            PLAN_PRICING_TABLE_INFO[changePlan?.trackPlan?.older_plan]
              ?.plan_type
          }{" "}
          to{" "}
          {PLAN_PRICING_TABLE_INFO[changePlan?.trackPlan?.new_plan]?.plan_type}
        </strong>
        .
      </Text>
      <Stack direction="row" className={styles.stackContainer}>
        {planPair.monthly !== FREE_PLAN_ID ? (
          <>
            <Box className={styles.stackItem}>
              <Text className={styles.stackText}>Subscription</Text>

              <Box
                className={`${styles.stackBox} ${
                  isAnnualSelected && styles.active
                } ${styles["mt-8"]}`}
                onClick={() => handlePlanSelection("annual")}
              >
                {isAnnualSelected && (
                  <CheckCircleIcon className={styles.checkCircleIcon} />
                )}
                <Text className={styles.boxTitle}>Annually</Text>
                <Text className={styles.boxPricing}>
                  <div>
                    <span className={styles.oldCost}>
                      ${PLAN_PRICING_TABLE_INFO[planPair.monthly]?.price}
                    </span>{" "}
                    <span className={styles.currentCost}>
                      ${PLAN_PRICING_TABLE_INFO[planPair.annual].price / 12}
                      /month
                    </span>
                  </div>
                  <span className={styles.warning}>
                    Billed annually ($
                    {PLAN_PRICING_TABLE_INFO[planPair.annual].price})
                  </span>
                </Text>
              </Box>

              <Box
                className={`${styles.stackBox} ${
                  !isAnnualSelected && styles.active
                } ${styles["mt-8"]}`}
                onClick={() => handlePlanSelection("monthly")}
              >
                {!isAnnualSelected && (
                  <CheckCircleIcon className={styles.checkCircleIcon} />
                )}
                <Text className={styles.boxTitle}>Monthly</Text>
                <Text className={styles.boxPricing}>
                  <span className={styles.currentCost}>
                    ${PLAN_PRICING_TABLE_INFO[planPair.monthly].price}/month
                  </span>
                </Text>
              </Box>
              <Text className={`${styles.saveCost}`}>
                You can save{" "}
                <strong>
                  $
                  {PLAN_PRICING_TABLE_INFO[planPair.monthly].price -
                    PLAN_PRICING_TABLE_INFO[planPair.annual].price / 12}{" "}
                  per month ($
                  {(PLAN_PRICING_TABLE_INFO[planPair.monthly].price -
                    PLAN_PRICING_TABLE_INFO[planPair.annual].price / 12) *
                    12}{" "}
                  per year)
                </strong>{" "}
                on the annual plan!
              </Text>
            </Box>
            <Box className={styles.stackItem}>
              <Text className={styles.stackText}>Selected Plan</Text>
              <PlanDescription
                planId={changePlan?.trackPlan?.new_plan}
                compareWithBase
              />
            </Box>
          </>
        ) : (
          <>
            <Box className={styles.stackItem}>
              <Text
                className={`${styles.freeText} ${
                  planPair.monthly !== FREE_PLAN_ID
                }`}
              >
                There is $0 charge for the free plan but you will have limited
                access to features.
              </Text>
              <Text
                className={`${styles.freeText} ${
                  planPair.monthly !== FREE_PLAN_ID
                }`}
              >
                <strong>Get more out of Blanka!</strong>
              </Text>
              <Button
                variant="contained"
                color="primary"
                className={styles.ctaButton}
                onClick={() => setOpen(true)}
              >
                Explore Plans
              </Button>
            </Box>
            <Box className={styles.stackItem}>
              <Text className={styles.stackText}>Selected Plan</Text>
              <PlanDescription
                planId={changePlan?.trackPlan?.new_plan}
                compareWithBase
              />
            </Box>
          </>
        )}

        <ChangePlanModal
          open={open}
          handleClose={handleClose}
          ignoreDowngrade
        />
      </Stack>
    </Box>
  );
}

export default Review;
