import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Text from "components/Text";
import shopifyLogo from "assets/images/shopify-logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import wixLogo from "assets/images/wix-logo.png";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { setProfile } from "state/profileSlice";
import {
  updateShop,
  getUser,
  connectStore,
  connectWoocommerceStore,
  connectWixStore,
} from "api";

// import LazyLoad, { forceVisible } from "react-lazyload";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAnalytics } from "hooks";
import OnboardingWrapper from "../OnboardingWrapper";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./ShopifyOnboardingStepOne.module.scss";

export default function OnboardingStepFour() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const shop = useSelector((state) => state.profile.shop);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shop) {
      return;
    }

    const setData = async (store) => {
      try {
        await connectStore({ token: store.token, url: store.url });
        const response = await getUser();
        analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_SUCCESS, {
          url: store.url,
        });
        dispatch(setProfile(response.data));

        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      } catch (e) {
        console.log(e);
        setError("There was a problem connecting your store, please try again");
        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      }
    };

    // check for shopify store connection
    const shopifyStore = JSON.parse(sessionStorage.getItem("shopify_store"));
    if (shopifyStore) {
      setLoading(true);
      setData(shopifyStore);
    }
  }, []);

  const handleSkip = async () => {
    analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_SKIP, {
      plan_id: shop.plan,
    });
    analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
      plan_id: shop.plan,
    });
    await updateShop(shop.id, {
      onboarding_step: 1,
      shop_signup_type: "STANDARD",
    });
    navigate("/");
  };

  const connectStoreAttempt = (integrationType) => {
    analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_ATTEMPT, {
      integration_type: integrationType,
    });

    switch (integrationType) {
      case "shopify":
        window.open("https://apps.shopify.com/blanka");
        break;
      case "woocommerce":
        window.open("https://wordpress.org/plugins/blanka/");
        break;
      case "wix":
        window.open(
          "https://www.wix.com/app-market/beauty-dropshipping-by-blanka"
        );
        break;
      default:
        break;
    }
  };

  return (
    <OnboardingWrapper>
      <div
        className={styles.onboardingStepFour}
        data-testid="OnboardingStepTwo"
      >
        <Text
          className="text--align-center text--spacing-0"
          color="medium-gray"
          fontSize={24}
          variant="h1"
        >
          Connect your online store
        </Text>

        {loading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}

        {!loading && (
          <div className={styles.storesContainer}>
            <div
              className={`${styles.storeContainer} ${styles.storeContainerActive}`}
            >
              <a
                target="_blanka"
                onClick={() => connectStoreAttempt("shopify")}
              >
                <LazyLoadImage
                  src={shopifyLogo}
                  alt="shopify logo"
                  width="125"
                  height="125"
                  effect="black-and-white"
                  visibleByDefault
                  placeholderSrc={shopifyLogo}
                />
              </a>
              <Text
                fontSize={12}
                className={`text--nunito text--bold ${styles.textShopType}`}
              >
                Shopify
              </Text>
            </div>
            <div
              className={`${styles.storeContainer} ${styles.storeContainerActive}`}
            >
              <a
                target="_blanka"
                onClick={() => connectStoreAttempt("woocommerce")}
              >
                <LazyLoadImage
                  src={wooCommerceLogo}
                  alt="woocommerce logo"
                  width="125"
                  height="125"
                  effect="black-and-white"
                  visibleByDefault
                  placeholderSrc={wooCommerceLogo}
                />
              </a>

              <Text
                fontSize={12}
                className={`text--nunito text--bold ${styles.textShopType}`}
              >
                WooCommerce
              </Text>
            </div>

            <div
              className={`${styles.storeContainer} ${styles.storeContainerActive}`}
            >
              <a target="_blanka" onClick={() => connectStoreAttempt("wix")}>
                <LazyLoadImage
                  src={wixLogo}
                  alt="wix logo"
                  width="125"
                  height="125"
                  effect="black-and-white"
                  visibleByDefault
                  placeholderSrc={wixLogo}
                />
              </a>

              <Text
                fontSize={12}
                className={`text--nunito text--bold ${styles.textShopType}`}
              >
                Wix
              </Text>
            </div>
            <div
              className={`${styles.storeContainer} ${styles.storeContainerInactive}`}
              style={{ marginRight: "0px" }}
            >
              <LazyLoadImage
                src={bigCommerceLogo}
                alt="bigcommerce logo"
                width="125"
                height="125"
                effect="black-and-white"
                visibleByDefault
                placeholderSrc={bigCommerceLogo}
              />

              <Text
                fontSize={15}
                className={`${styles.textComingSoon} text--bold text--white text--spacing-4`}
              >
                Coming Soon
              </Text>
              <Text
                fontSize={12}
                className={`text--nunito text--bold ${styles.textShopType}`}
              >
                BigCommerce
              </Text>
            </div>
          </div>
        )}

        <div className={styles.noticeContainer}>
          {!loading && (
            <Text
              fontSize={12}
              color="gray"
              className="text--pb-10 text-mobile--18"
            >
              Not ready to connect your store?{" "}
              <Link
                to="/?onboarding_complete=true"
                onClick={() => handleSkip()}
                className={`${styles.textRedirect} text--bold text--info`}
              >
                Click here to continue.
              </Link>
            </Text>
          )}

          <Text fontSize={12} color="gray">
            P.S you can still use Blanka with SquareSpace, BigCommerce or other
            e-commerce platforms.Learn more.
            <a
              target="__blank"
              href="https://faq.blankabrand.com/en/articles/5265856-how-do-i-use-blanka-with-ecommerce-platforms-like-wix-and-woocommerce"
              className={`${styles.textRedirect} text--bold text--info`}
            >
              Learn more.
            </a>
          </Text>

          <Text fontSize={12} color="error">
            {error}
          </Text>
        </div>
      </div>
    </OnboardingWrapper>
  );
}
