import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import Button from "components/Buttons/Button";
import { useMutation } from "react-query";
import Text from "components/Text";

import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import { loadStripe } from "@stripe/stripe-js";
import CartTotals from "components/CartTotals/CartTotals";
import { Elements } from "@stripe/react-stripe-js";

import PageHeader from "components/PageHeader";
import {
  CART_TYPE_INVENTORY,
  CART_TYPE_BLANKA_LABS_INVENTORY,
} from "constants/constants";

import { toast } from "react-toastify";
import { clearCart } from "state/cartSlice";

import { completeInventoryOrder, completeSamplesOrder } from "api";
import { useMediaQuery } from "react-responsive";
import { SMALL } from "constants/breakpoints";
import PaywallUpgradeModal from "components/Modals/PaywallUpgradeModal";
import ChangePlanModal from "components/Modals/ChangePlanModal";
import CheckoutAddPayment from "./components/CheckoutAddPayment";
import CheckoutConfirmPayment from "./components/CheckoutConfirmPayment";

import styles from "./CheckoutPaymentView.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA",
  ),
);

export default function CheckoutPaymentView() {
  const [clientSecret, setClientSecret] = useState(null);
  const [nextActionType, setNextActionType] = useState(null);
  const [openPaywallUpgradeModal, setOpenPaywallUpgradeModal] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const shop = useSelector((state) => state.profile.shop);
  const [isLoading, setLoading] = useState(false);
  const items = useSelector((state) => state.cart.items);
  const cart = useSelector((state) => state.cart);
  const shipping = useSelector((state) => state.cart.shipping);
  const completeInventoryOrderMutation = useMutation(completeInventoryOrder);
  const completeSamplesOrderMutation = useMutation(completeSamplesOrder);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handlePaymentSuccess = () => {
    setLoading(false);
    dispatch(clearCart());
    navigate("/checkout/thank-you");
  };

  const handleSubmit = () => {
    setLoading(true);

    if (
      cart.type === CART_TYPE_INVENTORY ||
      cart.type === CART_TYPE_BLANKA_LABS_INVENTORY
    ) {
      completeInventoryOrderMutation.mutate(
        {
          shipping,
          items,
        },
        {
          onSuccess: (response) => {
            if (response.data.requires_action) {
              setClientSecret(response.data.client_secret);
              setNextActionType(response.data.next_action?.type || null);
              setLoading(false);
              return;
            }

            setLoading(false);
            dispatch(clearCart());

            toast.success("Success! Your order has been placed.");

            navigate("/checkout/thank-you");
          },
          onError: () => {
            setLoading(false);
          },
        },
      );
    } else {
      // is sample order
      completeSamplesOrderMutation.mutate(
        {
          shipping,
          items,
          discount_id: cart.discount?.code || null,
        },
        {
          onSuccess: (response) => {
            if (response.data.requires_action) {
              setClientSecret(response.data.client_secret);
              setNextActionType(response.data.next_action?.type || null);
              setLoading(false);
              return;
            }

            setLoading(false);
            dispatch(clearCart());
            navigate("/checkout/thank-you");
          },
          onError: () => {
            setLoading(false);
          },
        },
      );
    }
  };

  return (
    <div
      className={`samples-checkout template-inventory-checkout__shipping checkout-shipping ${styles.checkoutPayment}`}
    >
      {!isMobile && <PageHeader title="Checkout - Payment" />}
      {isMobile && (
        <div className="checkout__breadcrumbs mb-30">
          <CheckoutFooter backLink="/cart" activeStep={2} />
          {isMobile && (
            <Divider orientation="horizontal" className={styles.divider} />
          )}
        </div>
      )}
      <Card className={styles.card} variant="outlined">
        <div className="shipping-card-left">
          <Text
            className="text--pb-10 text--bold"
            fontSize={!isMobile ? 24 : 21}
            variant="h2"
            color={!isMobile ? "medium-gray" : "gray"}
          >
            Payment Information
          </Text>
          <Text
            className={!isMobile ? "" : "mb-25 mt-5"}
            fontSize={!isMobile ? 10 : 12}
            color={!isMobile ? "medium-gray" : "gray"}
          >
            All transactions are secure and encrypted
          </Text>

          <Text
            className={
              !isMobile
                ? "text--nunito text--spacing-2 text--upper text--pb-5"
                : "text--nunito text--spacing-2 text--upper mb-5"
            }
            fontSize={!isMobile ? 10 : 9}
          >
            Credit Card Number
          </Text>

          {shop.has_payment_method ? (
            <>
              <div
                className={`${styles.creditCardDisplay} text--nunito text--mb-5 mb-10 mt-10`}
              >
                <p className={styles.stars}>
                  {" "}
                  <span>
                    **** **** ****{" "}
                    {shop.billing_address
                      ? shop?.billing_address.credit_card_digits
                      : "****"}
                  </span>
                </p>
              </div>

              <Text
                className={
                  !isMobile ? "text--upper text--spacing-1" : styles.textLink
                }
              >
                To update credit card visit{" "}
                <Link
                  className={` ${styles.TextLink} text--info`}
                  to="/profile/billing/update"
                >
                  My Account
                </Link>
              </Text>
            </>
          ) : (
            <CheckoutAddPayment />
          )}
        </div>
        {!isMobile && (
          <div className={`shipping-card-right ${styles.limitCartTotals}`}>
            <CartTotals cart={cart} step="shipping" />
          </div>
        )}
      </Card>

      {isMobile && (
        <div className="shipping-card-right">
          <CartTotals cart={cart} step="shipping" />
        </div>
      )}

      <div className="checkout__actions">
        {!isMobile && (
          <div className="checkout__breadcrumbs">
            <CheckoutFooter backLink="/checkout/shipping" activeStep={2} />
          </div>
        )}
        <div className="checkout-button-container">
          <Button
            disabled={!shop.has_payment_method}
            loading={isLoading}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            className={
              !isMobile
                ? "button button--primary"
                : "button button--primary mt-10"
            }
          >
            Complete Purchase
          </Button>

          <Elements stripe={stripePromise}>
            <CheckoutConfirmPayment
              nextActionType={nextActionType}
              clientSecret={clientSecret}
              handlePaymentSuccess={handlePaymentSuccess}
              handlePaymentError={() => {
                setNextActionType();
                setClientSecret();
                setLoading(false);
              }}
            />
          </Elements>
          <PaywallUpgradeModal
            open={openPaywallUpgradeModal}
            handleClose={() => setOpenPaywallUpgradeModal(false)}
            planId={shop.plan}
            handlePlanChange={() => setOpenChangePlanModal(true)}
          />
          <ChangePlanModal
            open={openChangePlanModal}
            handleClose={() => setOpenChangePlanModal(false)}
            setOpenChangePlanModal={setOpenChangePlanModal}
          />
        </div>
      </div>
    </div>
  );
}
