import React from "react";

import Text from "components/Text";
import Button from "components/Buttons/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import { PLAN_INFO, ANNUAL_PLAN_IDS } from "constants/plans";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import styles from "./ChangePlanPaymentConfirmation.module.scss";

export default function ChangePlanPaymentConfirmation(props) {
  const { shop, pendingPlanId, handleConfirm, handleCancelConfirm } = props;

  const planLabel = shop?.plan_label;

  return (
    <>
      <Text
        className={`text__header ${styles.header}`}
        fontSize={24}
        // color="medium-gray"
      >
        Confirm your subscription.
      </Text>

      <Text
        fontSize={16}
        variant="h4"
        color="peach"
        className={styles.planName}
      >
        {PLAN_INFO[pendingPlanId].name}
      </Text>

      <div className={styles.planDetailsContainer}>
        <KeyboardBackspaceIcon
          onClick={() => handleCancelConfirm()}
          className={styles.backIcon}
        />

        <div className={styles.planDetails}>
          <div className="text--align-left">
            {shop.has_trial && (
              <Text fontSize={14} className="text--bold">
                Billed in 14 days:
              </Text>
            )}
            {!shop.has_trial && (
              <Text fontSize={14} className="text--bold">
                Billed now:
              </Text>
            )}
            <Text fontSize={12}>Annual savings:</Text>
          </div>
          <div className="text--align-right">
            <Text fontSize={14} className="text--bold">
              ${PLAN_INFO[pendingPlanId].price}/yr
            </Text>
            <Text>${PLAN_INFO[pendingPlanId].annual_savings}/yr</Text>
          </div>
        </div>

        {shop.has_trial && (
          <p className="text--fs-10 text--align-left">
            After your free trial ends, you will automatically be charged $
            {PLAN_INFO[pendingPlanId].price}{" "}
            {ANNUAL_PLAN_IDS.includes(shop.plan) ? "annually" : "monthly"} until
            you cancel your subscription.
          </p>
        )}

        {!shop.has_trial && (
          <p className="text--fs-10 text--align-left">
            You will be charged ${PLAN_INFO[pendingPlanId].price} annually until
            you cancel your subscription.
          </p>
        )}

        <Button
          onClick={() => handleConfirm()}
          color="primary"
          variant="contained"
          className="button text--nunito button__change-plan"
          hasChevron
        >
          Confirm
        </Button>
        <Text
          onClick={() => handleCancelConfirm()}
          fontSize={10}
          className={styles.keepCurrentPlanText}
        >
          Keep current plan <ChevronRightIcon />
        </Text>
      </div>

      <img
        className={styles.poweredByStripe}
        src={poweredByStripeImg}
        alt="powered by stripe"
      />

      <Text fontSize={10} className={styles.TandC}>
        By creating an account, you agree to Blanka&apos;s{" "}
        <a
          target="__blank"
          href="https://blankabrand.com/blanka-terms-and-conditions/"
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          target="__blank"
          href="https://faq.blankabrand.com/en/articles/4880267-how-does-blanka-handle-returns"
        >
          Refund & Cancellation Policy
        </a>
      </Text>
    </>
  );
}
