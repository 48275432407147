import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { loadStripe } from "@stripe/stripe-js";
import { createSetupIntent, updateShopPayment } from "api";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);
const useStripePayment = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const dispatch = useDispatch();

  const initializePayment = async (planId) => {
    try {
      setStripeLoading(true);
      const response = await createSetupIntent(planId);
      setClientSecret(response.data.client_secret);
      setStripeLoading(false);
    } catch (error) {
      console.error("Error creating setup intent:", error);
      setStripeLoading(false);
    }
  };

  const handlePaymentMethodUpdate = async (paymentId, pendingPlanId) => {
    try {
      const response = await updateShopPayment(paymentId);
      toast.success("Success! Payment method updated.");
      return response;
    } catch (error) {
      console.error("Error updating payment method:", error);
      toast.error("Error updating payment method.");
      return null;
    }
  };

  return {
    clientSecret,
    stripeLoading,
    initializePayment,
    handlePaymentMethodUpdate,
  };
};

export default useStripePayment;
