import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

// MUI:
import { Box } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Link } from "react-router-dom";
// custom components and constants:
import Text from "components/Text";
import ChangePlanModal from "components/Modals/ChangePlanModal";
import { SMALL } from "constants/breakpoints.js";

// hooks:
// import { useProfile } from "hooks";

import styles from "./UpgradePlanNotification.module.scss";

function UpgradePlanNotification() {
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleOpenChangePlanModal = () => {
    setOpenChangePlanModal(true);
  };

  const handleClose = () => {
    setOpenChangePlanModal(false);
  };

  return (
    <>
      <Box className={styles.UpgradePlanNotification}>
        <>
          <Text className={styles.errorTitle} variant="body1">
            {!isMobile && <InfoOutlinedIcon className={styles.error} />}
            You are currently over your{" "}
            <Link to="/profile/" className={styles.reinforce}>
              limit(s) for this plan!
            </Link>
            You will need to upgrade before new customer orders can be
            processed.
          </Text>
          <Link
            onClick={handleOpenChangePlanModal}
            underline="none"
            className={styles.red}
          >
            <Text className={`${styles.links} ${styles.error}`} variant="body2">
              UPGRADE <ChevronRight />
            </Text>
          </Link>
        </>
      </Box>

      <ChangePlanModal open={openChangePlanModal} handleClose={handleClose} />
    </>
  );
}

UpgradePlanNotification.propTypes = {};

export default UpgradePlanNotification;
