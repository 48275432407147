import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

// hooks:
import {
  setQuestions,
  setChoosenPlan,
  resetDowngradeState,
  setPlanChangeLoading,
} from "state/changePlanSlice";
import useStripePayment from "hooks/useStripe";

// Custom components:
import ChangePlanPaymentConfirmation from "components/Modals/ChangePlanModal/components/ChangePlanPaymentConfirmation";
import { PAIRED_ANNUAL_AND_MONTHLY_PLANS, FREE_PLAN_ID } from "constants/plans";

import { SHOP_ONBOARDING_SHOPIFY } from "constants/constants";

import { useProfile, useAnalytics } from "hooks";
import { updatePlan } from "api";

import Feedback from "./components/Feedback";
import Review from "./components/Review";
import FeatureUsage from "./components/FeatureUsage";
import CommonLayout from "./layouts/CommonLayout/CommonLayout";
import { Transaction } from "../../api/trace";
import { DowngradeTraceContext } from "./DowngradeTraceContext";

const TRANSACTION_NAME = "/downgrade";

const OPERATION_NAME = "downgrade";

const TRANSACTION_DESCRIPTION = "Downgrade within Plans";

function DowngradeView() {
  // states:
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const questions = useSelector((state) => state.changePlan.questions);
  const changePlan = useSelector((state) => state.changePlan);
  const profileHook = useProfile();

  // Start the trace transaction (root span) for downgrade flow and sets the context
  const [transaction] = useState(
    Transaction.startTransaction(
      TRANSACTION_NAME,
      OPERATION_NAME,
      TRANSACTION_DESCRIPTION,
    ),
  );

  const shop = useSelector((state) => state.profile.shop);

  // hooks
  const { initializePayment } = useStripePayment();

  const navigate = useNavigate();
  const location = useLocation();

  const changePlanMutation = useMutation(updatePlan);

  // local states:
  const [questionsData, setQuestionsData] = useState(questions);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(
    changePlan?.trackPlan?.new_plan,
  );
  const [, setIsResponse] = useState(false);
  const [, setLoading] = useState(false);
  const [, setShowPaymentForm] = useState(false);

  // Keeps track of the routes we have on the flow:
  const routeOrder = [
    "/downgrade/feature-usage",
    "/downgrade/feedback",
    // "/downgrade/offers",
    "/downgrade/review",
  ];

  // make sure to redirect users that try to access the urls directly
  useEffect(() => {
    if (!shop.plan || !changePlan.trackPlan.new_plan) {
      navigate("/profile");
    }
  }, []);

  // Gets the current route index for when the NEXT btn is clicked:
  const currentRouteIndex = routeOrder.findIndex((path) =>
    location.pathname.endsWith(path),
  );

  const handleNext = () => {
    if (currentRouteIndex >= 0 && currentRouteIndex < routeOrder.length - 1) {
      transaction.span.data = questionsData;
      transaction.finishTransaction();
      const nextPath = routeOrder[currentRouteIndex + 1];
      navigate(nextPath);
    }
  };

  const handleBack = () => {
    if (currentRouteIndex >= 0) {
      const prevPath = routeOrder[currentRouteIndex - 1];
      navigate(prevPath);
    } else {
      // Handle the case when the user is at the first step
      navigate(-1);
    }
  };

  /**
   * Handle the case when the user wants to keep the current plan
   * @param {number} step - The current step
   */
  const handleKeepCurrentPlan = (stepName = "") => {
    analytics.sendEvent(analytics.DOWNGRADE_FLOW_EXIT, {
      step_name: stepName,
    });
    navigate("/profile");
  };

  const executeChangePlanMutation = async (id) => {
    changePlanMutation.mutate(id, {
      onSuccess: (response) => {
        if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
          window.location.href = response.data.confirmation_url;
          return;
        }

        setLoading(false);
        toast.success("Success! Your account has been updated.");
        setShowPaymentForm(false);
        setIsResponse(false);
        dispatch(resetDowngradeState());
        dispatch(setPlanChangeLoading(false));
        profileHook.refreshProfile().then(() => {
          navigate("/profile");
          analytics.sendEvent(analytics.DOWNGRADE_FLOW_COMPLETE, {
            old_plan: changePlan.trackPlan.older_plan,
            new_plan: changePlan.trackPlan.new_plan,
            downgrade_reason: changePlan.downgradeReason,
            downgrade_response: changePlan.downgradeResponse,
            scheduled_downgrade_date:
              profileHook.shop.subscription_next_billing,
          });
        });
      },
      onError: () => {
        dispatch(setPlanChangeLoading(false));

        setLoading(false);
      },
    });
  };

  const handleChangePlan = async (id, isStripePaymentNeeded) => {
    if (isStripePaymentNeeded) {
      await initializePayment(id);
      setShowConfirmationPage(true);
    } else {
      await executeChangePlanMutation(id);
    }
  };

  const handleUnderstoodProceed = async () => {
    dispatch(setPlanChangeLoading(true));
    executeChangePlanMutation(Number(changePlan.choosenPlan));

    transaction.span.data = changePlan.questions;
    transaction.finishTransaction();
  };

  const handleFeedbackChange = (id, isChecked, answer) => {
    const updatedQuestions = questionsData.map((question) => {
      if (question.id === id) {
        return { ...question, isChecked, answer }; // Keep the current question's state
      }
      return { ...question, isChecked: false, answer: "" }; // Uncheck and clear other questions
    });
    setQuestionsData(updatedQuestions);
    dispatch(setQuestions(updatedQuestions));
  };

  const getPlanPair = () => {
    const currentPlanId = changePlan?.trackPlan?.new_plan;

    // if currentPlanId is 1, return the first pair (monthly: 1, annual: 2
    if (currentPlanId === FREE_PLAN_ID)
      return { monthly: FREE_PLAN_ID, annual: FREE_PLAN_ID };

    // Find the plan pair object where the currentPlanId is either a key or a value
    const planPairObject = PAIRED_ANNUAL_AND_MONTHLY_PLANS.find(
      (planPair) =>
        currentPlanId in planPair ||
        Object.values(planPair).includes(currentPlanId),
    );

    if (!planPairObject) return {}; // Return empty object if no pair found

    const monthlyPlanId =
      currentPlanId in planPairObject
        ? currentPlanId
        : Object.keys(planPairObject)[0];
    const annualPlanId = planPairObject[monthlyPlanId];

    return { monthly: monthlyPlanId, annual: annualPlanId };
  };

  const [planPair, setPlanPair] = useState(getPlanPair());

  useEffect(() => {
    setQuestionsData(questions);

    setPlanPair(getPlanPair());
  }, [questions, changePlan]);

  useEffect(() => {
    const currentRoute = routeOrder[currentRouteIndex];
    if (currentRouteIndex === 0) {
      analytics.sendEvent(analytics.DOWNGRADE_FLOW_START, {
        old_plan: changePlan.trackPlan.older_plan,
        new_plan: changePlan.trackPlan.new_plan,
      });
    }
    analytics.sendEvent(analytics.DOWNGRADE_FLOW_STEP_CHANGE, {
      step: currentRoute.split("/").pop().toUpperCase(),
    });
  }, [currentRouteIndex]);

  const handlePlanSelection = (planType) => {
    const planPair = getPlanPair();
    setSelectedPlan(planPair[planType]);
    dispatch(setChoosenPlan(planPair[planType]));
  };

  return (
    <DowngradeTraceContext.Provider value={transaction}>
      <CommonLayout
        handleKeepCurrentPlan={handleKeepCurrentPlan}
        handleNext={handleNext}
        handleBack={handleBack}
        handleUnderstoodProceed={handleUnderstoodProceed}
        currentRouteIndex={currentRouteIndex}
      >
        <Routes>
          <Route
            path="feedback"
            element={
              <Feedback
                questions={questionsData}
                onFeedbackChange={handleFeedbackChange}
              />
            }
          />
          <Route
            path="feature-usage"
            element={
              <FeatureUsage
                selectedPlan={selectedPlan}
                planPair={planPair}
                handleKeepCurrentPlan={handleKeepCurrentPlan}
                handleNext={handleNext}
                handlePlanSelection={handlePlanSelection}
              />
            }
          />

          <Route
            path="review"
            element={
              <Review
                selectedPlan={selectedPlan}
                planPair={planPair}
                handlePlanSelection={handlePlanSelection}
                changePlan={changePlan}
              />
            }
          />
        </Routes>
        {showConfirmationPage && (
          <ChangePlanPaymentConfirmation
            shop={shop}
            handleConfirm={() => {
              handleChangePlan(changePlan.choosenPlan, true);
              setShowConfirmationPage(false);
            }}
            handleCancelConfirm={() => {
              setShowConfirmationPage(false);
            }}
            pendingPlanId={changePlan.choosenPlan}
          />
        )}
      </CommonLayout>
    </DowngradeTraceContext.Provider>
  );
}

export default DowngradeView;
