/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "components/Buttons/Button";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Text from "components/Text";
import ChangePlanModal from "components/Modals/ChangePlanModal";
import { routes } from "routes";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAbTests } from "hooks/useABTests";

import PublishedDiscontinuedProductNotification from "components/Notifications/PublishedDiscontinuedProductNotification/PublishedDiscontinuedProductNotification";

import blankaLogo from "assets/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import {
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  CUSTOM_PRODUCT_STATUS_NONE,
} from "constants/constants";
import { VIP_PLAN, PLAN_FREE_ID } from "constants/plans";
import { SMALL } from "constants/breakpoints.js";
import { HelpIcon, MarketingIcon } from "components/Icons";
import HeaderCartIcon from "components/Icons/HeaderCartIcon/HeaderCartIcon";
import CloseIcon from "@mui/icons-material/Close";

import { useMediaQuery } from "react-responsive";

import { grey } from "@mui/material/colors";

import { useAnalytics } from "hooks";

import { setIsOpen } from "state/myLogoSlice";
import FreeTrialTag from "components/Labels/FreeTrialTag/FreeTrialTag";
import UpgradeBlock from "../Sidebar/components/UpgradeBlock";

import styles from "./SidebarMobile.module.scss";

const drawerWidth = "100%";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  alignItems: "space-between",
  justifyContent: "center",
  width: "100%",
  height: "80px",
  backgroundColor: "white",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "center",
  width: "100%",
  height: "80px",
}));

export default function SidebarMobile({ hasHeaderBar }) {
  const [open, setOpen] = useState(false);
  const cart = useSelector((state) => state.cart);
  const shop = useSelector((state) => state.profile.shop);
  const abTests = useAbTests();

  const analytics = useAnalytics();
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [isVipOnly, setIsVipOnly] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenLinkNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleLogoGenerator = (e, route) => {
    if (route.path === "my-logo") {
      dispatch(setIsOpen(true));
    }
    if (route.external) {
      e.preventDefault();
      handleOpenLinkNewTab(route.path);
    }
    analytics.sendEvent(analytics.SIDEBAR_NAVIGATION, { path: route.name });
    setOpen(false);
  };

  useEffect(() => {
    // Check if the URL contains the string "my-logo"
    if (window.location.href.includes("my-logo")) {
      setOpen(false);
      navigate("/");
    }
  }, []);

  return (
    <Box className={`${styles.sidebarMobile} `}>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        className={`${styles.appBarWrapper} ${
          shop.trial_days_remaining !== 0 && styles.adjustSize
        } `}
      >
        <Toolbar className={styles.toolbarWrapper}>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src={blankaLogo} alt="blanka" className={styles.blankaLogo} />
          </Link>
          <Link to="/cart" className={styles.cartIconWrapper}>
            <HeaderCartIcon hasItems={cart.items.length > 0} />
            <span>{cart.items.length > 0 && <>({cart.items.length})</>}</span>
          </Link>
        </Toolbar>
        {shop.trial_days_remaining !== 0 && (
          <Box className={`${styles.center} ${styles["mtd-16"]}`}>
            <FreeTrialTag />
          </Box>
        )}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          className={`${styles.draweHeaderWrapper} ${hasHeaderBar ? styles.hasHeader : ""}`}
        >
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
          <div className={styles.accountLinks}>
            <Link
              className={`${styles.textLink} text--nunito`}
              to="/profile"
              onClick={handleDrawerClose}
            >
              My Account
            </Link>
            <Link
              className={`${styles.textLink} text--nunito`}
              to="/logout?no_persist=true"
            >
              Logout
            </Link>
          </div>
        </DrawerHeader>
        <Divider />
        <List className={styles.submenu}>
          {routes
            .filter((route) => route.menu)
            .map((route, index) => (
              <div key={index}>
                {route.sectionTitle && (
                  <Text
                    fontSize={9}
                    variant="body1"
                    color="gray"
                    className="text--upper text--spacing-2-4 mt-25 ml-5"
                  >
                    {route.sectionTitle}
                  </Text>
                )}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "navItem activeItem" : "navItem"
                  }
                  to={route.path}
                  style={{ textDecoration: "none" }}
                  key={999}
                  onClick={(e) => handleLogoGenerator(e, route)}
                >
                  <ListItem button key={route.name}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.name} color={grey} />
                    <Tooltip title="VIP">
                      <ListItemIcon tooltip="VIP">
                        {route.tag && route.tag}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                </NavLink>
              </div>
            ))}

          <NavLink
            className="navItem"
            to="#"
            style={{ textDecoration: "none" }}
            key={999}
            onClick={() => {
              analytics.sendEvent(analytics.SIDEBAR_NAVIGATION, {
                path: "marketing",
              });
              window.open(
                "https://blankabrand.com/collections/marketing-creative"
              );
            }}
          >
            <ListItem button key="marketing">
              <ListItemIcon>
                <MarketingIcon />
              </ListItemIcon>
              <ListItemText primary="Marketing" />
            </ListItem>
          </NavLink>

          <NavLink
            className="navItem"
            to="#"
            style={{ textDecoration: "none" }}
            key={99}
            onClick={() => {
              analytics.sendEvent(analytics.SIDEBAR_NAVIGATION, {
                path: "faq",
              });
              window.open("http://faq.blankabrand.com/");
            }}
          >
            <ListItem button key="help">
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </NavLink>
        </List>
        {shop.plan === PLAN_FREE_ID && <UpgradeBlock />}
        {isMobile &&
          shop.plan !== PLAN_FREE_ID &&
          shop.custom_product_status === CUSTOM_PRODUCT_STATUS_NONE && (
            <Button
              size="small"
              color="primary"
              onClick={() => handleLogoGenerator({ path: "my-logo" })}
              className={styles.btn}
            >
              Add my Logo To Products
            </Button>
          )}
        {isMobile &&
          shop.plan_type !== PLAN_FREE_ID &&
          shop.custom_product_status === CUSTOM_PRODUCT_STATUS_IN_PROGRESS && (
            <Button
              size="small"
              color="primary"
              disabled
              className={styles.btn}
            >
              Visualization in progress
            </Button>
          )}
        {isMobile &&
          shop.plan !== PLAN_FREE_ID &&
          shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE && (
            <div />
          )}
      </Drawer>
      <ChangePlanModal
        handleClose={() => setOpenChangePlanModal(false)}
        open={openChangePlanModal}
        isFreePlanLimit
      />
    </Box>
  );
}
