import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate } from "react-router-dom";
import {
  selectBrandPlateOrderStatus,
  selectCurrentOrder,
  selectOrders,
} from "state/brandPlateSlice";

// Material UI Components
import Grid from "@mui/material/Grid";

// Project Components & Constants
import { SMALL } from "constants/breakpoints";

import styles from "./BrandPlateNav.module.scss";

const BRAND_PLATE_NAV = [
  {
    slug: "/brand-plate/",
    title: "New Brand Plate",
  },
  {
    slug: "/brand-plate/setup",
    title: "Current Brand Plate",
  },
  {
    slug: "/brand-plate/past-orders",
    title: "Past Brand Plates",
  },
];

function BrandPlateNav() {
  const navigate = useNavigate();
  const orders = useSelector(selectOrders);
  const currentOrder = useSelector(selectCurrentOrder);
  const brandPlateOrderStatus = useSelector(selectBrandPlateOrderStatus);
  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const [brandPlateNav, setBrandPlateNav] = useState(BRAND_PLATE_NAV);

  const handleSlugOnClick = (e, slug) => {
    e.preventDefault();
    navigate(slug);
  };

  return (
    <Grid className="paper " container spacing={2}>
      <Grid item xs={12} md={12}>
        <div
          className={`nav-div ${isMobile && styles["mt-10"]} ${styles.navContainer}`}
        >
          {brandPlateNav.map((item, index) => (
            <>
              <NavLink
                key={`${item.slug}-${item.title}`}
                to={item.slug}
                end
                className={({ isActive }) =>
                  isActive ? styles.navItemActive : styles.navItem
                }
                onClick={(e) => handleSlugOnClick(e, item.slug)}
              >
                {item.title}
              </NavLink>
              {index !== brandPlateNav.length - 1 && (
                <span
                  className={styles.navDivider}
                  key={`${item.slug}-${item.title}`}
                >
                  {" "}
                  |{" "}
                </span>
              )}
            </>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

export default BrandPlateNav;
