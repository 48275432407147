import React from "react";
import PropTypes from "prop-types";

// mui:
import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// constants:
import Text from "components/Text";
import { PLAN_PRICING_TABLE_INFO } from "constants/plans";

// Import styles
import styles from "./BillingCycleSelector.module.scss";

export default function BillingCycleSelector({
  isAnnualSelected,
  handlePlanSelection,
  planPair,
}) {
  return (
    <Box className={styles.billingCycleSelector}>
      {/* Annual Plan Box */}
      <Box
        onClick={() =>
          handlePlanSelection({ type: "annual", id: Number(planPair.annual) })
        }
        className={`${styles.stackBox} ${isAnnualSelected ? styles.active : ""} ${styles["mt-8"]}`}
      >
        {isAnnualSelected && (
          <CheckCircleIcon className={styles.checkCircleIcon} />
        )}
        <Text className={styles.boxTitle}>Annually</Text>
        <Text className={styles.boxPricing}>
          <div>
            <span className={styles.oldCost}>
              ${PLAN_PRICING_TABLE_INFO[planPair.monthly]?.price}
            </span>{" "}
            <span className={styles.currentCost}>
              ${PLAN_PRICING_TABLE_INFO[planPair.annual].price / 12}/month
            </span>
          </div>
          <span className={styles.warning}>
            Billed annually (${PLAN_PRICING_TABLE_INFO[planPair.annual].price})
          </span>
        </Text>
      </Box>

      {/* Monthly Plan Box */}
      <Box
        onClick={() =>
          handlePlanSelection({ type: "monthly", id: Number(planPair.monthly) })
        }
        className={`${styles.stackBox} ${!isAnnualSelected ? styles.active : ""} ${styles["mt-8"]}`}
      >
        {!isAnnualSelected && (
          <CheckCircleIcon className={styles.checkCircleIcon} />
        )}
        <Text className={styles.boxTitle}>Monthly</Text>
        <Text className={styles.boxPricing}>
          <span className={styles.currentCost}>
            ${PLAN_PRICING_TABLE_INFO[planPair.monthly].price}/month
          </span>
        </Text>
      </Box>
      <Text className={styles.stackText}>
        By purchasing an annual plan, you agree to Blanka&apos;s{" "}
        <a
          className={styles.link}
          href="https://blankabrand.com/blanka-terms-and-conditions/"
          target="__blank"
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          className={styles.link}
          href="https://faq.blankabrand.com/en/articles/4880267-how-does-blanka-handle-returns"
          target="__blank"
        >
          Refund & Cancellation Policy
        </a>
      </Text>
    </Box>
  );
}

BillingCycleSelector.propTypes = {
  isAnnualSelected: PropTypes.bool.isRequired,
  handlePlanSelection: PropTypes.func.isRequired,
  planPair: PropTypes.shape({
    monthly: PropTypes.number.isRequired,
    annual: PropTypes.number.isRequired,
  }).isRequired,
};
