/* eslint-disable import/no-cycle */
import apiService from "api/apiService";
import { setProfile, setShop } from "state/profileSlice";
import { store } from "state/store";

export const createUser = (user) =>
  apiService.post(`/users/register/`, {
    ...user,
  });

export const getUser = () =>
  apiService.get("/users/me/").then((res) => {
    store.dispatch(setProfile(res.data));
    return res;
  });

export const updateUser = (user) =>
  apiService.put("/users/", {
    ...user,
  });

export const updateShop = (id, data) =>
  apiService.patch(`/shop/${id}/`, data).then((res) => {
    store.dispatch(setShop(res.data));
    return res;
  });

export const updateShopPayment = (data) =>
  apiService.post("/shop/update_payment/", { payment_id: data });

export const updatePlan = (planId, endTrial = false, coupon = null) =>
  apiService.post("/shop/update_plan/", {
    plan_id: planId,
    end_trial: endTrial,
    coupon,
  });

export const downgradeToFreePlan = () =>
  apiService.post("/shop/downgrade-to-free/", {});

export const cancelPlanDowngrade = () =>
  apiService.post("/shop/cancel-plan-downgrade/", {});

export const connectStore = (data) =>
  apiService.post(`/shop/connect-shopify-store/`, data);

export const connectWoocommerceStore = (data) =>
  apiService.post(`/shop/connect-woocommerce-store/`, data);

export const connectWixStore = (data) =>
  apiService.post(`/shop/connect-wix-store/`, data);

export const newVisualizationRequest = (data) =>
  apiService.post(`/shop/new-visualization/`, data);

export const uploadShopLogo = (id, data) => {
  const bodyFormData = new FormData();
  if (data.logo) {
    bodyFormData.append("logo", data.logo);
  }
  return apiService.patch(`/shop/${id}/`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export function updateShopLogo(id, logo) {
  const bodyFormData = new FormData();
  bodyFormData.append("logo", logo);
  bodyFormData.append("logo_uploaded", true);

  return apiService.patch(`/shop/${id}/`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/* My Logo Generator CRUD */
export const getMyLogoGenerated = () =>
  apiService.get("/shop-logos/").then((res) => res);

export const uploadMyLogoGenerated = (data) => {
  const bodyFormData = new FormData();
  bodyFormData.append("logo", data.logo);
  bodyFormData.append("logo_type", data.logo_type);

  return apiService.post(`/shop-logos/`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createDirectUrl = async ({ originalFileName }) =>
  apiService.post("shop-logos/create-direct-upload-url/", {
    original_file_name: originalFileName,
  });

export const uploadFileToS3 = async (directUrl, file) => {
  await fetch(directUrl, {
    method: "PUT",
    body: file,
  });
};

export const publishFile = async ({ fileName, orderId }) =>
  apiService.put("shop-logos/publish-file/", {
    file_name: fileName,
    order_id: orderId,
  });

export const deactivateExistingLogos = () => {
  apiService.post("/shop/deactivate-existing-logos/").then((res) => res);
};

export const postCreateCustomProducts = () =>
  apiService.post("/shop/create-custom-products/").then((res) => res);

export const updateShopAutomaticPayments = (data) =>
  apiService.post("/shop/set-autopay-customer-orders/", { enabled: data });

export const getShopSettings = async () => apiService.get(`v1/shop-settings/`);

export const createShopSettings = async (data) =>
  apiService.post(`v1/shop-settings/`, data);

export const getAvailableCredit = () =>
  apiService.get("/shop/available-credit/");

export const validateSubscription = async (chargeId, coupon) =>
  apiService.post("/shop/validate-subscription/", {
    subscriptionId: chargeId,
    coupon,
  });

// Subscription discount:
export const createSubscriptionDiscount = async (code) =>
  apiService.post("/subscription-discount/", { code });
export const deleteSubscriptionDiscount = (id) =>
  apiService.delete(`/subscription-discount/${id}/`);
