import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Elements } from "@stripe/react-stripe-js";
import AddPaymentMethodForm from "components/AddPaymentMethodForm";
import {
  PLAN_INFO,
  ANNUAL_PLAN_IDS,
  PLAN_PRICING_TABLE_INFO,
} from "constants/plans";
import { useMediaQuery } from "react-responsive";

import Text from "components/Text";
import { createSetupIntent, updateShopPayment, updateShop } from "api";
import { setShop } from "state/profileSlice";

import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import { STRIPE_THEME } from "theme";

import moment from "moment";

import { Box, Divider } from "@mui/material";
import { useProfile } from "hooks";
import { SMALL } from "constants/breakpoints";
import OnboardingProgressStepper from "../OnboardingProgressStepperB";
import OnboardingWrapperB from "../OnboardingWrapperB";

import SidePanelPlanSelectionB from "../components/SidePanelPlanSelectionB/SidePanelPlanSelectionB";
import styles from "./OnboardingStepThreeB.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

export default function OnboardingStepThreeB() {
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();
  const shop = useSelector((state) => state.profile.shop);
  const profileHook = useProfile();
  const planLabel = shop?.plan_label;

  const [loading, setLoading] = useState(true);
  const [message] = useState(null);
  const [isAnnualSelected, setIsAnnualSelected] = useState(
    profileHook.isAnnualPlan()
  );
  const [selectedPlan, setSelectedPlan] = useState(shop.plan);

  const plan = {
    planId: shop.plan,
    planType: shop.plan_type,
  };

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const planPair = profileHook.getPlanPair();

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const getData = async () => {
      try {
        const response = await createSetupIntent(PLAN_INFO[shop.plan]?.price);
        setClientSecret(response.data.client_secret);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleNextStep = () => {
    updateShop(shop.id, { onboarding_step: 3 }).then((res) => {
      dispatch(setShop(res.data));
    });
  };

  const handleBack = () => {
    setLoading(true);
    updateShop(shop.id, { onboarding_step: 1 }).then((res) => {
      setLoading(false);
      dispatch(setShop(res.data));
    });
  };

  const handleAddPaymentMethod = async (paymentId) => {
    await updateShopPayment(paymentId);
  };

  const getNewSetupIntent = async (planId) => {
    try {
      const response = await createSetupIntent(PLAN_INFO[planId]?.price);
      setClientSecret(response.data.client_secret);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  const handlePlanSelection = async (plan) => {
    setIsAnnualSelected(plan.type === "annual");
    setSelectedPlan(plan.id);
    console.log("plan", plan);

    // Update the shop state with the new plan:
    const updatedShop = {
      ...shop,
      plan: plan.id,
      plan_type: plan.type,
    };
    dispatch(setShop(updatedShop));

    await getNewSetupIntent(updateShop.plan);
  };

  useEffect(() => {
    const fetchUpdatedShop = async () => {
      const response = await updateShop(shop.id, {
        plan: selectedPlan,
        plan_type: isAnnualSelected ? "annual" : "monthly",
      });
      dispatch(setShop(response.data));
    };

    fetchUpdatedShop();
  }, [selectedPlan, isAnnualSelected, dispatch, shop.id]);

  return (
    <OnboardingWrapperB>
      <Box className={styles.sideBox}>
        <SidePanelPlanSelectionB
          plan={{
            planId: selectedPlan,
            planType: isAnnualSelected ? "annual" : "monthly",
          }}
          planPair={planPair}
          onPrevious={handleBack}
          onPlanSelect={handlePlanSelection}
          isAnnualSelected={isAnnualSelected}
        />
      </Box>
      <Box className={styles.onboardingStepThreeB}>
        <Box className={styles.planDetailsContainer}>
          <Box className={styles.upsellContainer}>
            <Text variant="h1" className={styles.title}>
              Enjoy a 14-day FREE trial
            </Text>
            <Text variant="h1" className={styles.title}>
              $0
            </Text>
          </Box>
          <Box className={styles.upsellContainer}>
            <Text className={styles.subtitle}>
              Amount after trial ends on{" "}
              {moment().add(14, "days").format("MMM DD")}
            </Text>
            {ANNUAL_PLAN_IDS.includes(selectedPlan) && (
              <Text className={styles.subtitle}>
                ${PLAN_PRICING_TABLE_INFO[selectedPlan].price}/yr
              </Text>
            )}
            {!ANNUAL_PLAN_IDS.includes(selectedPlan) && (
              <Text className={styles.subtitle}>
                ${PLAN_PRICING_TABLE_INFO[selectedPlan].price}/mth
              </Text>
            )}
          </Box>
          <Divider className={styles.divider} />
        </Box>

        {loading && (
          <Box className={styles.loading}>
            <CircularProgress />
          </Box>
        )}

        {message && <p>{message}</p>}

        <Box className={styles.stripeContainer}>
          <Text variant="h1" className={styles.payment}>
            Payment Method
          </Text>
          {clientSecret && !loading && (
            <Elements options={options} stripe={stripePromise}>
              <Box className={styles.paymentWrapper}>
                <AddPaymentMethodForm
                  handleNextStep={handleNextStep}
                  handleAddPaymentMethod={handleAddPaymentMethod}
                  submitButtonText="Sign me up"
                  planId={selectedPlan}
                />
              </Box>
            </Elements>
          )}
        </Box>
        <Text className={styles.onBoardingTermsAndConditions}>
          By signing up you agree to Blanka&apos;s&nbsp;
          <a
            target="__blank"
            href="https://blankabrand.com/blanka-terms-and-conditions/"
          >
            Terms and Conditions
          </a>
          &nbsp; and&nbsp;
          <a
            target="__blank"
            href="https://faq.blankabrand.com/en/articles/4880267-how-does-blanka-handle-returns"
          >
            Refund & Cancellation Policy
          </a>
        </Text>

        <Box className={styles.poweredByContainer}>
          <img
            className={styles.poweredBy}
            src={poweredByStripeImg}
            alt="powered by stripe"
          />
        </Box>
      </Box>
    </OnboardingWrapperB>
  );
}
