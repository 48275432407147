import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { NavLink, useNavigate, Link } from "react-router-dom";
import queryString from "query-string";

import TextField from "@mui/material/TextField";
import Text from "components/Text";
import { useMutation } from "react-query";

import { sendNewPassword } from "api";

import OnboardingWrapper from "views/Onboarding/OnboardingWrapper";
import Button from "components/Buttons/Button";

import PasswordStrengthBar from "components/PasswordStrengthBar";

import styles from "./PasswordResetUpdateView.module.scss";

function PasswordResetUpdateView() {
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);

  const navigate = useNavigate();

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState();
  const [email, setEmail] = useState();

  const passwordResetMutation = useMutation(sendNewPassword);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }

    const { token, email } = queryString.parse(window.location.search);

    setToken(token);
    setEmail(email);
  }, []);

  const handleSetPassword = () => {
    setLoading(true);
    passwordResetMutation.mutate(
      { password, token, email },
      {
        onSuccess: () => {
          setLoading(false);
          setToken("");
          setEmail("");
          setSuccessMessage("Success, your password has been updated, please ");
        },
        onError: () => {
          setToken("");
          setEmail("");
          setLoading(false);
          setMessage(
            "Oops! We could not set your password. Please ensure your new password meets the strength requirements.",
          );
        },
      },
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSetPassword();
  };

  return (
    <div className="password-reset-template">
      <OnboardingWrapper>
        <div className="password-reset-content">
          <div className="signup-container mb-10">
            <Text className="signup-container__title text--nunito" color="gray">
              Password Reset
            </Text>
          </div>

          <div className={styles.formContainer}>
            <form
              className={styles.form}
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
            >
              <PasswordStrengthBar
                password={password}
                width="300px"
                margin="10px 0 0 0px"
                handleStrengthChange={(strength) =>
                  setPasswordStrength(strength)
                }
                className={styles.passwordStrengthBar}
                hideStengthText
              />

              <TextField
                id="password"
                required
                InputLabelProps={{ shrink: true }}
                label="Password"
                value={password}
                type="password"
                name="password"
                variant="outlined"
                className="mb-20"
                onChange={(e) => setPassword(e.target.value)}
              />

              <TextField
                id="confirm-password"
                label="Confirm Password"
                required
                className="input-fields"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={confirmPassword}
                type="password"
                name="confirm_password"
                style={{ marginTop: 10 }}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <NavLink
                to="/login"
                className="signup-container__sign-in text--nunito"
              >
                Return to login
              </NavLink>

              <Button
                disabled={!password || !confirmPassword || passwordStrength < 3}
                className="button__reset"
                variant="contained"
                loading={loading}
                type="submit"
                color="primary"
                onClick={handleSetPassword}
              >
                Reset Password
              </Button>
            </form>
            {password !== confirmPassword && confirmPassword.length > 6 && (
              <p className={`${styles.errorMessage} text--error`}>
                Passwords do not match
              </p>
            )}
            <p className={`${styles.errorMessage} text--error mb-0`}>
              {message}
            </p>

            {successMessage && (
              <div className={styles.success}>
                <p className="text--blue">{successMessage} </p>
                <Link to="/login" className="text--peach text--bold">
                  login
                </Link>
              </div>
            )}

            {message && (
              <ul className={styles.passwordStrength}>
                <li className="text__secure-message text--nunito  text--error">
                  Your password can&apos;t be too similar to your other personal
                  information.
                </li>
                <li className="text__secure-message text--nunito  text--error">
                  Your password must contain at least 8 characters.
                </li>
                <li className="text__secure-message text--nunito  text--error">
                  Your password can’t be a commonly used password.
                </li>
                <li className="text__secure-message text--nunito  text--error">
                  Your password can’t be entirely numeric.
                </li>
              </ul>
            )}
          </div>
        </div>
      </OnboardingWrapper>
    </div>
  );
}

export default PasswordResetUpdateView;
