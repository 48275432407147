import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// mui:
import { Box } from "@mui/material";

// custom components:
import Text from "components/Text";
import blankaLogo from "assets/images/logo.png";
import { SMALL } from "constants/breakpoints";
import SignUpForm from "./SignUpForm";

import SidePanelOnboardingStepB from "../components/SidePanelOnboardingStepB/SidePanelOnboardingStepB";

import styles from "./OnboardingStepOneB.module.scss";
import OnboardingWrapperB from "../OnboardingWrapperB";

export default function OnboardingStepOneB() {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <OnboardingWrapperB>
      {!isMobile && (
        <>
          <Box className={styles.sideBox}>
            <Box className={styles.onboardingStepOneB}>
              <Box className={styles.welcomeContainer}>
                <Text
                  className="text--bold text--nunito text--primary text--spacing-6"
                  fontSize={34}
                  variant="h4"
                >
                  WELCOME TO{" "}
                </Text>
                <img
                  src={blankaLogo}
                  alt="Logo"
                  className={styles.blankaLogo}
                />
              </Box>

              <Box className={styles.signUpText}>
                <Text fontSize={24} className="text--bold text--blue">
                  Sign up for FREE!
                </Text>

                <Link
                  to="/login"
                  className={`${styles.textLogin} text--nunito text--info`}
                >
                  Already have an account?{" "}
                  <span className={styles.redText}>Log in</span>
                </Link>
              </Box>

              <SignUpForm />
            </Box>
          </Box>
          <Box className={styles.sideBox}>
            <SidePanelOnboardingStepB />
          </Box>
        </>
      )}
      {isMobile && (
        <>
          <Box className={styles.sideBox}>
            <SidePanelOnboardingStepB />
          </Box>
          <Box className={styles.sideBox}>
            <Box className={styles.onboardingStepOneB}>
              <Box className={styles.signUpText}>
                <Text fontSize={24} className="text--bold text--blue">
                  Sign up for FREE!
                </Text>

                <Link
                  to="/login"
                  className={`${styles.textLogin} text--nunito text--info`}
                >
                  Already have an account?{" "}
                  <span className={styles.redText}>Log in</span>
                </Link>
              </Box>

              <SignUpForm />
            </Box>
          </Box>
        </>
      )}
    </OnboardingWrapperB>
  );
}
