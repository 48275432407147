import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Card, CardActions, CardContent, Stack } from "@mui/material";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import { useCart, useProfile, useAnalytics } from "hooks";
import { useSelector } from "react-redux";
import { selectCurrentOrder } from "state/brandPlateSlice";
import ChangePlanModal from "components/Modals/ChangePlanModal";
import BrandPlateMockupFiles from "../BrandPlateMockupFiles";
import styles from "./BrandPlatePendingApproval.module.scss";

function BrandPlatePendingApproval({
  approveBrandPlateMockup,
  requestChangesToBrandPlateMockup,
  isLoading,
}) {
  const currentOrder = useSelector(selectCurrentOrder);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const profileHook = useProfile();
  const analytics = useAnalytics();

  const handleApproveBrandPlateMockup = () => {
    if (profileHook.isFreePlan()) {
      setUpgradeModalOpen(true);
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "brand_plate_approve_mockup",
      });
    } else {
      approveBrandPlateMockup(currentOrder.id);
    }
  };

  const handleRequestChangesToBrandPlateMockup = () => {
    if (profileHook.isFreePlan()) {
      setUpgradeModalOpen(true);
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "brand_plate_request_changes",
      });
    } else {
      requestChangesToBrandPlateMockup(currentOrder.id);
    }
  };

  return (
    <Box mt={2}>
      <Stack className={styles.stackContainer}>
        <Card className={styles.pendingReviewBox}>
          <CardContent>
            <Text
              color="primary"
              fontSize="16"
              variant="h2"
              className={styles.pendingReviewTitle}
            >
              Your pending Brand Plate mock-up sheet is ready!
            </Text>
            <Box className={styles.pendingReviewContainer}>
              <Box className={styles.downloadContainer}>
                <Text
                  className={styles.mockupTitle}
                  variant="body1"
                  fontSize="14"
                >
                  Product mock-up:
                </Text>

                <BrandPlateMockupFiles mockups={currentOrder.mockups} />
              </Box>
              <Text fontSize="14" variant="body1">
                Please review the file(s) above carefully. If you are satisfied
                with the results, click the ACCEPT MOCK-UP button below. If
                there are changes you would like to be made, click the REQUEST
                REVISIONS button.{" "}
                <a
                  href="https://faq.blankabrand.com/en/articles/9261512-how-do-brand-plate-revisions-work"
                  target="_blank"
                  rel="noreferrer"
                >
                  (You may request up to 2 revisions)
                </a>
              </Text>
            </Box>
          </CardContent>
          <CardActions className={styles.actionContainer}>
            <Box className={styles.actionBox}>
              <Button
                size="small"
                variant="contained"
                loading={isLoading === "approve"}
                color="primary"
                startIcon={<CheckIcon />}
                onClick={() => handleApproveBrandPlateMockup()}
              >
                Accept mock-up
              </Button>
              <Text fontSize="14">or</Text>
              <Button
                size="small"
                loading={isLoading === "change"}
                variant="outlined"
                color="primary"
                onClick={() => handleRequestChangesToBrandPlateMockup()}
              >
                Request revisions
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Stack>
      <ChangePlanModal
        isFreePlanLimit
        handleClose={() => setUpgradeModalOpen(false)}
        open={upgradeModalOpen}
        profile={profileHook.profile}
        title="Upgrade your plan to continue"
      />
    </Box>
  );
}

export default BrandPlatePendingApproval;
