import { useCallback } from "react";
import { useSelector } from "react-redux";

import { TEST_GENNY_ALLOW } from "constants/ab_tests";

export function useAbTests() {
  const shop = useSelector((state) => state.profile.shop);
  const profile = useSelector((state) => state.profile.profile);

  const isAllowGenny = useCallback(() => {
    console.log("ALLOW GENNY:", shop.feature_flags.includes(TEST_GENNY_ALLOW));
    return shop.feature_flags.includes(TEST_GENNY_ALLOW);
  });

  return {
    isAllowGenny,
  };
}
