import React from "react";
import { useMediaQuery } from "react-responsive";

// mui:
import { Box } from "@mui/material";

// components and constants:
import Text from "components/Text";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import { StoreMallDirectory } from "@mui/icons-material";

// assets:
import LeftSideHand from "assets/images/onboarding-side-panel-hand-lipstick.png";
import blankaLogo from "assets/images/logo.png";
import FirstTestmonial from "assets/images/testimonial-1.png";
import SecondTestmonial from "assets/images/testimonial-2.png";
import ShopifyLogo from "assets/images/Shopify_Logo.png";

import { SMALL } from "constants/breakpoints";
import styles from "./SidePanelOnboardingStepB.module.scss";
import Slider from "../Slider/Slider";

const slides = [
  {
    id: 1,
    text: ["Zero Commitments", "Zero Miniumums", "Zero Risk"],
    image: FirstTestmonial,
    testmonial:
      "“I am using this app to launch my beauty biz. I absolutely love the open lines of communication. I've never experienced anything like it, they literally walk you through the entire process step by step!”",
    caption: "",
  },
  {
    id: 2,
    text: [
      "Fast Shipping & Turn around",
      "Dropshipped to your customer",
      "Best-In-class customer support",
    ],
    image: ShopifyLogo,
    testmonial: "Customers love us on Shopify!",
    caption: "",
  },
  {
    id: 3,
    text: ["Dedicated team of beauty experts", "Easy to use app interface"],
    image: SecondTestmonial,
    testmonial:
      "“I love, love, love this private label beauty company. The quality of the products are very good and professional looking. I am glad that I found Blanka.”",
    caption: "",
  },
];

export default function SidePanelOnboardingStepOneB() {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  return (
    <Box className={styles.sidePanelOnboardingStepOneB}>
      {isMobile && (
        <img src={blankaLogo} alt="Logo" className={styles.blankaLogo} />
      )}
      <Text variant="h1" className={styles.title}>
        Create branded beauty & wellness products with zero minimums{" "}
      </Text>

      {!isMobile && (
        <Box>
          <Slider numSteps={slides.length}>
            {slides.map((slide) => (
              <Box className={styles.stackContainer}>
                <Box key={slide.id} className={styles.flexContainer}>
                  {slide.text.map((t) => (
                    <Text key={t} className={styles.text}>
                      {t}
                    </Text>
                  ))}
                </Box>
                <Box key={slide.id} className={styles.flexContainer}>
                  <Box className={styles.testmonialContainer}>
                    <img
                      src={slide.image}
                      alt=""
                      className={
                        slide.id % 2 !== 0 ? styles.avatar : styles.shopify
                      }
                    />
                    <Text className={styles.testmonial}>
                      {slide.testmonial}
                    </Text>

                    <Text className={styles.caption}>{slide.caption}</Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      )}

      {isMobile && (
        <Box className={styles.ratingWrapper}>
          <img src={slides[1].image} alt="" className={styles.shopify} />
          <Text className={styles.testmonial}>{slides[1].testmonial}</Text>
        </Box>
      )}

      <Box className={styles.imgContainer}>
        <img src={LeftSideHand} alt="" />
      </Box>
    </Box>
  );
}
