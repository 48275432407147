import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  Box,
  Checkbox,
  Divider,
  Input,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormGroup,
  TableCell,
  TableRow,
  Tooltip,
  Stack,
} from "@mui/material";

import { ArrowUpwardOutlined } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ProductStatusIndicator from "components/ProductStatusIndicator/ProductStatusIndicator";

import Text from "components/Text";
import DiscountTag from "components/Labels/DiscountTag/DiscountTag";
import ProductTag from "components/Labels/ProductTag";

import {
  CART_TYPE_INVENTORY,
  PRODUCT_TYPE_CUSTOM,
  CART_TYPE_SAMPLE,
} from "constants/constants";

import {
  selectTiersByProductId,
  handleVoucherSuggestions,
} from "state/cartSlice";
import styles from "./CartItemMobile.module.scss";

function CartItemMobile({
  item,
  cartType,
  handleUpdate,
  handleRemoveItem,
  handleOnBlurredInputField,
}) {
  const { product } = item;
  const cart = useSelector((state) => state.cart);

  const [isChecked, setIsChecked] = useState(
    item.product.branded_box_available &&
      item.product.product_type === PRODUCT_TYPE_CUSTOM,
  );

  const tiersByProductId = useSelector(selectTiersByProductId);

  useEffect(() => {
    setIsChecked(item.branded_box);
  }, [item]);

  useEffect(() => {
    const updatedProduct = { ...item };

    updatedProduct.branded_box =
      item.product.branded_box_available && cart.type === CART_TYPE_INVENTORY;

    handleUpdate(updatedProduct, item.quantity);
  }, []);

  const handleBrandedBoxCheck = (event) => {
    setIsChecked(event.target.checked);
    const updatedProduct = { ...item };
    updatedProduct.branded_box = event.target.checked;
    handleUpdate(updatedProduct, item.quantity);
  };

  const customizedTooltip = (quantity) => {
    if (quantity <= 0) {
      return (
        <Box bgcolor="white" className={styles.tooltipContent}>
          <Text className={styles.tooltipTitleError}>
            Quantity cannot be 0!
          </Text>
        </Box>
      );
    }

    return (
      <Box bgcolor="white" className={styles.tooltipContent}>
        <Text className={styles.tooltipTitle}>Volume discounts available!</Text>
        <Text className={styles.tooltipText}>Order quantities:</Text>
        <Text className={styles.tooltipText}>
          <strong>12 - 48</strong> for{" "}
          <span className={styles.peach}> 5% off</span>
        </Text>
        <Text className={styles.tooltipText}>
          <strong>49 - 96</strong> for{" "}
          <span className={styles.peach}> 10% off</span>
        </Text>
        <Text className={styles.tooltipText}>
          <strong>97+</strong> for{" "}
          <span className={styles.peach}> 15% off</span>
        </Text>
      </Box>
    );
  };

  return (
    <>
      <TableRow className={styles.cartItemMobile}>
        <Box
          className={
            item.product?.product_base?.is_discontinued &&
            styles.adjustDiscontinued
          }
        />
        <TableCell sx={{ display: "flex" }}>
          <div className={styles.imgWrapper}>
            {item.product?.product_base?.out_of_stock &&
              !item.product?.product_base?.is_discontinued && (
                <div className={styles.diagonal} />
              )}
            <div
              className={
                item.product?.product_base?.is_discontinued &&
                styles.diagonalLines
              }
            />
            <img
              src={product.image}
              alt=""
              className={`${styles.imgBox} ${
                item.product?.product_base?.is_discontinued ||
                (item.product?.product_base?.out_of_stock &&
                  styles.imageOutOfStock)
              }`}
            />
          </div>
          <Box className={styles.statusWrapper}>
            <ProductStatusIndicator product={item.product} />
          </Box>
          <div className={styles.productInfo}>
            <span className={styles.name}>{product.name}</span>
            <span className={styles.skuCode}>{product.sku || "SKU-CODE"}</span>
            <ProductTag productType={product.product_type} />
            <Stack className={styles.stackContainer}>
              {cartType === CART_TYPE_INVENTORY && (
                <Text className={styles.withDiscountLower}>
                  ${item?.unit_cost_with_savings}
                </Text>
              )}
              {item.quantity >= 12 && cartType === CART_TYPE_INVENTORY && (
                <Text className={styles.noDiscount}>${product?.cost}</Text>
              )}
            </Stack>
          </div>
        </TableCell>
        <TableCell>
          <FormControl className={styles.quantityInput}>
            <Input
              id="standard-adornment-amount"
              value={Number.isNaN(item.quantity) ? "" : item.quantity}
              type="number"
              onChange={(e) => handleUpdate(item, e.target.value)}
              startAdornment={<InputAdornment position="start" />}
              endAdornment={
                item.quantity < 1 && (
                  <Tooltip
                    arrow
                    title={customizedTooltip(item.quantity)}
                    classes={{ tooltip: styles.customTooltip }}
                  >
                    <WarningAmberIcon className={styles.tooltipError} />
                  </Tooltip>
                )
              }
              require
              readOnly={cart.status === "loading" || product}
            />
          </FormControl>
        </TableCell>
        <TableCell>
          <Box className={styles.totalCost}>
            {cartType === CART_TYPE_INVENTORY ? (
              <Text className={styles.withDiscount}>
                ${item.total_cost_with_savings}
              </Text>
            ) : (
              <Text className="text--nunito text--bold">
                ${item.total_cost}
              </Text>
            )}

            {item.quantity >= 12 && cartType === CART_TYPE_INVENTORY && (
              <>
                <Text className={styles.noDiscount}>${item.total_cost}</Text>

                <Box className={styles.boxContainer}>
                  <DiscountTag
                    label={`${Number(item.discount?.value)}% off`}
                    color="primary"
                  />
                </Box>
              </>
            )}
            {cart.status === "idle" && (
              <a
                className={styles.removeBtn}
                onClick={(e) => handleRemoveItem(item.id)}
              >
                Remove
              </a>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {cartType === CART_TYPE_INVENTORY &&
        (handleVoucherSuggestions(tiersByProductId, item) ||
          item.product.branded_box_available) && (
          <TableRow className={styles.cartItemMobile}>
            <TableCell
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "0 !important",
              }}
            >
              {item.product.branded_box_available && (
                <Box className={styles.brandedBoxProduct}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!item.product.branded_box_available}
                          checked={isChecked}
                          onChange={(event) => handleBrandedBoxCheck(event)}
                        />
                      }
                      label="Add Branded Boxes $0.40/Ea"
                    />
                  </FormGroup>
                </Box>
              )}
              {handleVoucherSuggestions(tiersByProductId, item) && (
                <Box className={styles.discountWarning}>
                  <ArrowUpwardOutlined className={styles.icon} />
                  {handleVoucherSuggestions(tiersByProductId, item)}
                </Box>
              )}
            </TableCell>
          </TableRow>
        )}
      <Divider
        orientation="horizontal"
        className={styles.divider}
        sx={{ width: "100%" }}
      />
    </>
  );
}

CartItemMobile.propTypes = {
  item: PropTypes.object.isRequired,
  cartType: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemoveItem: PropTypes.func.isRequired,
};

CartItemMobile.defaultProps = {};

export default CartItemMobile;
