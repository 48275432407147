import CircularProgress from "@mui/material/CircularProgress";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createSetupIntent, QUERY, updatePlan, updateShopPayment } from "api";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import {
  ANNUAL_PLAN_IDS,
  FREE_PLAN_ID,
  PLAN_FREE_ID,
  PLAN_INFO,
} from "constants/plans";
import { usePlanChange, useProfile, useQueryParam } from "hooks";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  choosenPlan,
  resetDisplayUpgradeMessage,
  setChoosenPlan,
  updateDowngradeStatus,
} from "state/changePlanSlice";

import { Box } from "@mui/material";
import queryClient from "api/queryClient";
import FreeTrialTag from "components/Labels/FreeTrialTag";
import PlanSwitch from "components/Switch/components/PlanSwitch";
import Text from "components/Text";
import UpdatePaymentMethodModalForm from "components/UpdatePaymentMethodModalForm";
import { SMALL } from "constants/breakpoints";
import { SHOP_ONBOARDING_SHOPIFY, TRY_FOR_FREE } from "constants/constants";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { STRIPE_THEME } from "theme";
import PlanTable from "../../Tables/PlanTable";
import ChangePlanPaymentConfirmation from "./components/ChangePlanPaymentConfirmation";

import styles from "./ChangePlanModal.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

export default function ChangePlanModal(props) {
  const {
    open,
    handleClose,
    subtitle,
    isFreePlanLimit,
    freePlanLimitSubText,
    ignoreDowngrade,
    paywallTitle,
    paywallSubtitle,
    title,
    isVIPOnly,
  } = props;

  const queryParams = useQueryParam();

  const usePlanChangeHook = usePlanChange();

  const changePlanMutation = useMutation(updatePlan);
  const [loading, setLoading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const dispatch = useDispatch();

  const profileHook = useProfile();
  const navigate = useNavigate();
  const shop = useSelector((state) => state.profile.shop);

  const [clientSecret, setClientSecret] = useState();
  const [isResponse, setIsResponse] = useState(false);
  const [pendingPlanId, setPendingPlanId] = useState();

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);

  const [, setPlanCadence] = useState();

  const { isOnTrial } = useProfile();
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  // derived state:
  // directly derived from the shop's plan label, updates only when shop.plan_label changes.
  const planLabel = shop?.plan_label;

  // show monthly plans if already monthly or shopify billing type
  const [switchChecked, setSwitchChecked] = useState(
    !ANNUAL_PLAN_IDS.includes(shop.plan)
  );

  const handleChange = (event) => {
    setSwitchChecked(event.target.checked);
  };

  const setSetupIntent = async (id) => {
    try {
      // await getUser();
      setStripeLoading(true);
      const response = await createSetupIntent(PLAN_INFO[id].price);
      setClientSecret(response.data.client_secret);
      setStripeLoading(false);
    } catch (e) {
      setStripeLoading(false);
    }
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      // window.scrollTo(0, 0);
    };
    onbeforeunload();
  }, []);

  // Allow deep linking to the change plan modal to set teh cadence for either monthly or annual
  useEffect(() => {
    if (queryParams.get("cadence")) {
      setPlanCadence(queryParams.get("cadence"));
      if (queryParams.get("cadence").toLocaleLowerCase() === "annual") {
        setSwitchChecked(false);
      }
    }
  }, [queryParams.get("cadence")]);

  const executeChangePlanMutation = (id) => {
    changePlanMutation.mutate(id, {
      onSuccess: (response) => {
        if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
          window.location.href = response.data.confirmation_url;
          return;
        }

        queryClient.refetchQueries(QUERY.getUser).then(() => {
          setLoading(false);
          toast.success("Success! Your account has been updated.");
          handleClose();
          setShowPaymentForm(false);
          setIsResponse(false);
        });
      },
      onError: (err) => {
        console.error(err);
        setLoading(false);
      },
    });
  };

  const handleChangePlan = async (id, confirmed, bypass = false) => {
    // bypass is used to get a round a race condition with stripe returning
    // the payment save info

    if (
      !shop.has_payment_method &&
      !bypass &&
      id !== PLAN_FREE_ID &&
      !profileHook.isShopifyBilling()
    ) {
      setPendingPlanId(id);
      setShowPaymentForm(true);
      await setSetupIntent(id);
      return;
    }

    if (
      usePlanChangeHook.isDowngrading(shop.plan, id) &&
      /*! shop.has_trial */ !shop.subscription_status !== "trialing" &&
      !ignoreDowngrade
    ) {
      dispatch(updateDowngradeStatus({ plan: id }));
      dispatch(
        choosenPlan({
          older_plan: shop.plan,
          new_plan: id,
        })
      );
      dispatch(setChoosenPlan(id));

      console.log(id);

      // do not show downgrade flow if on trial
      // users are allowed to freely move around plans on trial
      if (!profileHook.isOnTrial()) {
        // if downgrading to free go to feature usage step first
        // inter paying plans dont go to feature usage
        if (id === FREE_PLAN_ID) {
          navigate("/downgrade/feature-usage");
        } else {
          navigate("/downgrade/feedback");
        }
        return;
      }
    }

    // check if switching to an annual plan
    if (
      ANNUAL_PLAN_IDS.includes(id) &&
      !confirmed &&
      !bypass &&
      id !== shop.plan
    ) {
      setPendingPlanId(id);
      setShowConfirmationPage(true);
      return;
    }

    setLoading(true);
    dispatch(resetDisplayUpgradeMessage());
    executeChangePlanMutation(id);
  };

  const handleAddPaymentMethod = async (paymentId) => {
    updateShopPayment(paymentId)
      .then((res) => {
        if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
          window.location.href = res.data.confirmation_url;
        }
        handleClose();
        queryClient.refetchQueries([QUERY.getUser]);
        toast.success("Success! Your account has been updated.");
        setShowPaymentForm(false);
        setIsResponse(false);
      })
      .catch((err) => {
        console.error(err);
      });

    executeChangePlanMutation(pendingPlanId);
  };

  useEffect(() => {
    if (
      shop.id &&
      queryParams.get("setup_intent") &&
      queryParams.get("plan_id")
    ) {
      setIsResponse(true);
      setPendingPlanId(queryParams.get("plan_id"));
      if (queryParams.get("setup_intent_client_secret") !== null) {
        setClientSecret(queryParams.get("setup_intent_client_secret"));
      }
      setStripeLoading(false);
      setShowPaymentForm(true);
    }
  }, []);

  const handlePlanAutoUpgrade = async () => {
    handleChangePlan(queryParams.get("plan_id"), true).then((res) => {
      queryClient.refetchQueries([QUERY.getUser]);
      if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = res.data.confirmation_url;
      }
    });
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  return (
    <ModalWrapper
      handleClose={() => {
        handleClose();
        setShowPaymentForm(false);
        setShowConfirmationPage(false);
      }}
      isOpen={open}
      data-testid="ChangePlantModal"
      className={`${styles.changePlanWrapper}`}
    >
      {!showPaymentForm && !isResponse && !showConfirmationPage && (
        <>
          {isOnTrial() && shop?.trial_days_remaining > 0 && (
            <Box className={styles.positioningFreeTrialTag}>
              <FreeTrialTag date={shop?.subscription_next_billing} />
            </Box>
          )}
          {isFreePlanLimit ? (
            <Text
              className="text--bold text__header"
              color="medium-gray"
              variant="h2"
              fontSize={21}
            >
              {title ||
                `Oops! You've reached your plan limit. Select ${isVIPOnly ? "the VIP plan" : "any paid plan"} below to continue.`}
            </Text>
          ) : (
            <>
              <Text
                color="medium-gray"
                fontSize={!isMobile ? 21 : 18}
                variant="h1"
              >
                {title || "Pick Your Plan"}
              </Text>
              <Text className="text__body text--nunito">
                {freePlanLimitSubText ||
                  "Select any paid plan below to brand products."}
              </Text>
            </>
          )}
          <>
            {shop.has_trial && (
              <Text className={styles.modalSubTitle}>
                Try a 14-day trial for free now!
              </Text>
            )}

            <Box className={styles.boxContainer}>
              <Text
                className={`text__body ${
                  switchChecked ? "text--nunito" : "text--peach text--bold"
                }`}
              >
                Annually
              </Text>
              <PlanSwitch handleChange={handleChange} checked={switchChecked} />
              <Text
                className={`text__body ${
                  !switchChecked ? "text--nunito" : "text--peach text--bold"
                }`}
              >
                Monthly
              </Text>
            </Box>

            <Text fontSize={18} className="">
              {subtitle}
            </Text>
          </>

          <div className={styles.plans}>
            <PlanTable
              onPaidPlanSelect={handleChangePlan}
              onFreePlanSelect={handleChangePlan}
              loading={loading}
              constant={TRY_FOR_FREE}
              switchChecked={switchChecked}
            />
          </div>
          <Text className={styles.warning}>
            <span>* applies to published products</span>
            <span>
              **1 free Brand Plate setup is included with each paid plan.
              Subsequent Brand Plates are an additional cost.
            </span>
          </Text>
        </>
      )}

      {showPaymentForm && !isResponse && (
        <>
          <Text
            className="text--bold text__header"
            fontSize={21}
            color="medium-gray"
          >
            You&apos;re one step away from starting your brand.
          </Text>

          {shop.has_trial && (
            <Text
              className="text--bold text--nuntio text__try-free"
              fontSize={14}
            >
              Start your 14-day free trial of the{" "}
              {PLAN_INFO[pendingPlanId].name} now!
            </Text>
          )}

          {!shop.has_trial && (
            <p className="text--bold text--nuntio text__try-free">
              Your subscription to the {PLAN_INFO[pendingPlanId].name} will
              start immediately!
            </p>
          )}
        </>
      )}

      {stripeLoading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}

      {showPaymentForm && (
        <div className={styles.stripeContainer}>
          {clientSecret && !stripeLoading && (
            <Elements options={options} stripe={stripePromise}>
              <UpdatePaymentMethodModalForm
                handleAddPaymentMethod={handleAddPaymentMethod}
                redirectParams={`plan_id=${pendingPlanId}&upgrading=true`}
                handleSuccess={() => handlePlanAutoUpgrade()}
                plan={pendingPlanId}
              />
            </Elements>
          )}
        </div>
      )}

      {showConfirmationPage && (
        <ChangePlanPaymentConfirmation
          shop={shop}
          handleConfirm={() => {
            handleChangePlan(pendingPlanId, true);
            setShowConfirmationPage(false);
          }}
          handleCancelConfirm={() => {
            setShowConfirmationPage(false);
          }}
          pendingPlanId={pendingPlanId}
        />
      )}
    </ModalWrapper>
  );
}

ChangePlanModal.propTypes = {
  open: PropTypes.bool,
  isVIPOnly: PropTypes.bool,
};

ChangePlanModal.defaultProps = {
  open: false,
  isVIPOnly: false,
};
