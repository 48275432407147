/* eslint-disable import/no-cycle */
import { FILTER_PREMIUM } from "constants/filters";
import { PRODUCT_TYPE_UNBRANDED } from "constants/constants";

import apiService from "api/apiService";

export const getProducts = (
  category = FILTER_PREMIUM,
  productType = PRODUCT_TYPE_UNBRANDED,
  page = 1
) =>
  apiService.get(
    `/products/?page=${page}&product_type=${productType}${
      category !== "all" ? `&category=${category}` : ""
    }`
  );

export const getProduct = (productId) =>
  apiService.get(`/products/${productId}/`);

export const getDiscount = (productId, quantity) =>
  apiService.get(`/discounts/${productId}/?quantity=${quantity}`);

export const getMyProducts = () => apiService.get(`/shop-products/`);

export const addProduct = (data) =>
  apiService.post("/shop-product/add-product/", { product_id: data.id });

export const publishProduct = (id) =>
  apiService.post("/shop/publish_product/", { product_id: id });

export const unpublishProduct = (id) =>
  apiService.post("/shop/unpublish_product/", { product_id: id });

export const deleteShopProduct = (id) =>
  apiService.delete(`/shop-products/${id}/`);
