import React from "react";
import propTypes from "prop-types";

// MUI:
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";

// customized components:
import Text from "components/Text";

// third-party libraries:
import moment from "moment";

import styles from "./OutOfStockTag.module.scss";

export default function OutOfStockTag(props) {
  const { expiryDate, isFullWidth } = props;

  const outOfStockTagClass = isFullWidth
    ? styles.outOfStockTagFull
    : styles.outOfStockTag;

  const tagWithNoExpiryDate = !expiryDate ? styles.noExpiryDate : "";

  const getProperWording = () => {
    // the method .day returns the day of the week, so we need to use .date
    const day = Number(moment(expiryDate).date());

    if (day >= 1 && day < 8) {
      return "early";
    }

    if (day >= 8 && day < 23) {
      return "mid";
    }

    return "late";
  };

  return (
    <div className={outOfStockTagClass}>
      <EnhancedEncryptionOutlinedIcon className={styles.tagIcon} />
      {!expiryDate ? (
        <Text className={styles.tag}>Out of stock</Text>
      ) : (
        <Text className={styles.tag}>
          Out of stock until {getProperWording()}{" "}
          {moment(expiryDate).format("MMM, YY")}
        </Text>
      )}
    </div>
  );
}

OutOfStockTag.propTypes = {
  expiryDate: propTypes.string,
  isFullWidth: propTypes.bool,
};

OutOfStockTag.defaultProps = {
  expiryDate: null,
  isFullWidth: false,
};
