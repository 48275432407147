import React from "react";
import PropTypes from "prop-types";

// mui:
import { Box } from "@mui/material";

import styles from "./OnboardingWrapperB.module.scss";

export default function OnboardingWrapperB(props) {
  const { children } = props;
  return <Box className={styles.onboardingWrapperB}>{children}</Box>;
}

OnboardingWrapperB.propTypes = {
  children: PropTypes.node.isRequired,
};
