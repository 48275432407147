import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

// MUI:
// import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Collapse from "@mui/material/Collapse";
import FormGroup from "@mui/material/FormGroup";
import { ArrowUpward } from "@mui/icons-material";
import { Divider } from "@mui/material";

// Components & constants:
import Text from "components/Text";
import Button from "components/Buttons/Button";
import PageHeader from "components/PageHeader";
import CartTotals from "components/CartTotals/CartTotals";
import { CART_TYPE_INVENTORY, CART_TYPE_SAMPLE } from "constants/constants";
import { SMALL } from "constants/breakpoints";
import AccountAddressCard from "components/Cards/AccountAddressCard/AccountAddressCard";
import { CheckMarkIcon } from "components/Icons";
import ConfirmRemoveAddressModal from "components/Modals/ConfirmRemoveAddressModal/ConfirmRemoveAddressModal";
import AccountAddressesForm from "components/Forms/AccountAddressesForm";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import {
  MAX_ACCOUNT_ADDRESSES_TO_DISPLAY_FOR_CHECKOUT,
  ACCOUNT_ADDRESSES_CREATED,
  ACCOUNT_ADDRESSES_UPDATED,
} from "constants/accountAddresses";

// hooks and services:
import useAccountAddresses from "hooks/useAccountAddresses";

import { useCart } from "hooks";

import {
  getShippingAddressesAsync,
  setIsAdding,
  setIsEditing,
  setAddressToShip,
  selectStatus,
} from "state/accountAddressesSlice";

import CartDiscountForm from "../CheckoutView/components/CheckoutDiscountForm";

import styles from "./CheckoutShippingView.module.scss";

function CheckoutShippingView() {
  const navigate = useNavigate();
  const cartHook = useCart();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const cartShippingState = useSelector((state) => state.cart.shipping);

  const [storeAtBlanka, setStoreAtBlanka] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  // store & states for Addressess:
  const {
    addresses,
    addressToShip,
    maxAddresses,
    isEditing,
    isAdding,
    handleOnClickEditAddress,
    handleOnRemoveAddress,
    handleOnSubmitForm,
    handleOnCancelForm,
  } = useAccountAddresses();

  const [open, setOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const status = useSelector(selectStatus);

  function syncAddressesAndShipping() {
    if (addresses.length === 0) {
      cartHook.updateShipping({});
      return;
    }

    if (addresses.length > 0) {
      if (cart.type === CART_TYPE_INVENTORY && storeAtBlanka) {
        cartHook.updateShipping({});
        return;
      }

      // check if there is a shipping address in the cart, if not, put the default one:
      if (addressToShip && Object.keys(addressToShip).length === 0) {
        const defaultAddress = addresses.find((address) => address.default);
        dispatch(setAddressToShip(defaultAddress));
        cartHook.updateShipping({
          ...defaultAddress,
        });
        return;
      }

      // if there is a shipping address in the cart, update the shipping address:
      dispatch(setAddressToShip(addressToShip));
      cartHook.updateShipping({
        ...addressToShip,
      });
    }
  }

  // initial state for shipping in SAMPLES or INVENTORY orders:
  useEffect(() => {
    dispatch(getShippingAddressesAsync());
    dispatch(setIsEditing(false));
    if (addresses.length === 0) {
      dispatch(setIsAdding(true));
    } else {
      dispatch(setIsAdding(false));
    }
  }, []);

  useEffect(() => {
    if (addresses.length === 0) {
      dispatch(setIsAdding(true));
    } else {
      dispatch(setIsAdding(false));
    }
  }, [addresses.length]);

  // handle the shipping case:
  useEffect(() => {
    if (status === "idle") {
      syncAddressesAndShipping();
    }
  }, [status]);

  useEffect(() => {
    if (
      status === ACCOUNT_ADDRESSES_CREATED ||
      status === ACCOUNT_ADDRESSES_UPDATED
    ) {
      dispatch(getShippingAddressesAsync());
      toast.success("Address has been saved!");
    }
  }, [status]);

  const handleOpenConfirmRemoveAddressModal = (id) => {
    setSelectedAddressId(id);
    setOpen(true);
  };

  const handleEditAddress = (id) => {
    handleOnClickEditAddress(id);
  };

  const handleOnClickRemoveAddress = () => {
    handleOnRemoveAddress(selectedAddressId);
    if (addressToShip.id === selectedAddressId) {
      dispatch(setAddressToShip({}));
    }
    setOpen(false);
  };

  const onSubmit = (e, data) => {
    handleOnSubmitForm(e, data);
  };

  const handleUseNewAddress = () => {
    dispatch(setIsEditing(false));
    dispatch(setIsAdding(true));
  };

  const onCancel = () => {
    handleOnCancelForm();
  };

  const onSelectAddressToShip = (address) => {
    dispatch(setAddressToShip(address));
    dispatch(getShippingAddressesAsync());
  };

  const renderAddresses = () => {
    const firstFiveAddresses = addresses.slice(0, 5);
    const restOfAddresses = addresses.slice(5);

    return (
      <>
        {firstFiveAddresses.map((address) => (
          <Box key={address.id} className={styles.addressContainer}>
            <Box
              className={`${
                address.id === addressToShip?.id ? styles.tagActive : ""
              }`}
              onClick={() => onSelectAddressToShip(address)}
            >
              {address.id === addressToShip?.id && (
                <CheckMarkIcon className={styles.tagDefaultIcon} />
              )}
              <AccountAddressCard
                key={address.id}
                address={address}
                handleOnClickEditAddress={handleEditAddress}
                handleOpenConfirmRemoveAddressModal={
                  handleOpenConfirmRemoveAddressModal
                }
              />
            </Box>
          </Box>
        ))}
        <Collapse in={showMore}>
          {restOfAddresses.map((address) => (
            <Box key={address.id} className={styles.addressContainer}>
              <Box
                className={`${
                  address.id === addressToShip?.id ? styles.tagActive : ""
                }`}
                onClick={() => onSelectAddressToShip(address)}
              >
                {address.id === addressToShip?.id && (
                  <CheckMarkIcon className={styles.tagDefaultIcon} />
                )}
                <AccountAddressCard
                  key={address.id}
                  address={address}
                  handleOnClickEditAddress={handleEditAddress}
                  handleOpenConfirmRemoveAddressModal={
                    handleOpenConfirmRemoveAddressModal
                  }
                />
              </Box>
            </Box>
          ))}
        </Collapse>
      </>
    );
  };

  const handleSubmit = () => {
    navigate("/checkout/payment");
  };

  const shippingValid = () => {
    if (storeAtBlanka) {
      return true;
    }

    if (
      !cartShippingState.first_name ||
      !cartShippingState.last_name ||
      !cartShippingState.address_one ||
      !cartShippingState.city ||
      !cartShippingState.country ||
      !cartShippingState.zip_code ||
      !cartShippingState.phone ||
      !cart.shipping.shipping_cost
    ) {
      return false;
    }

    return true;
  };

  return (
    <div
      className={`samples-checkout template-inventory-checkout__shipping checkout-shipping ${styles.cartShipping}`}
    >
      {!isMobile && <PageHeader title="Checkout" />}
      {isMobile && (
        <div className="checkout__breadcrumbs mb-30">
          <CheckoutFooter backLink="/cart" activeStep={1} />
          {isMobile && (
            <Divider orientation="horizontal" className={styles.divider} />
          )}
        </div>
      )}

      <Card className={styles.card} variant="outlined">
        <div className="shipping-card-left">
          {cart.type !== CART_TYPE_SAMPLE && (
            <Text
              variant="body1"
              color="peach"
              className="text--left text--bold text--nunito text--fs-15 mb-20 "
            >
              WHERE DO YOU WANT YOUR INVENTORY STORED?
            </Text>
          )}

          {cart.type !== CART_TYPE_SAMPLE && (
            <FormGroup>
              <FormControlLabel
                onChange={async (e) => {
                  if (e.target.checked) {
                    setStoreAtBlanka(true);
                    dispatch(getShippingAddressesAsync());
                  }
                }}
                disabled={cart.type === CART_TYPE_SAMPLE}
                checked={storeAtBlanka}
                className={styles.label}
                control={<Checkbox />}
                label="STORE MY INVENTORY AT THE BLANKA WAREHOUSE"
              />

              <FormControlLabel
                onChange={(e) => {
                  if (e.target.checked) {
                    setStoreAtBlanka(false);
                  }
                  dispatch(getShippingAddressesAsync());
                }}
                checked={!storeAtBlanka}
                control={<Checkbox />}
                className={styles.label}
                label="SHIP MY INVENTORY TO ME "
              />
            </FormGroup>
          )}

          <Collapse in={!storeAtBlanka}>
            <Text
              variant="h1"
              className={
                !isMobile
                  ? styles.title
                  : "text--left text--gray text--georgia-bold text--fs-24 mt-15"
              }
            >
              Shipping Information
            </Text>
            <Box className={styles.boxContainer}>
              {!isAdding &&
                !isEditing &&
                addresses.length !== 0 &&
                status === "idle" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleUseNewAddress}
                    disabled={addresses.length >= maxAddresses}
                  >
                    Use new Address
                  </Button>
                )}
            </Box>
            {(isAdding || isEditing) && status === "idle" && (
              <Box>
                <Text
                  variant="h4"
                  className={
                    !isMobile
                      ? styles.subtitle
                      : "text--left text--gray text--georgia-bold text--fs-24 mt-15"
                  }
                >
                  {isAdding ? "Save" : "Edit"} Address
                </Text>
                <AccountAddressesForm onSubmit={onSubmit} onCancel={onCancel} />
              </Box>
            )}
            {!(isAdding || isEditing) && status === "idle" && renderAddresses()}
            {addresses.length > MAX_ACCOUNT_ADDRESSES_TO_DISPLAY_FOR_CHECKOUT &&
              !(isEditing || isAdding) && (
                <div
                  className={styles.showMoreBtn}
                  onClick={() => setShowMore(!showMore)}
                >
                  <span className={styles.label}>
                    {showMore ? "Show Less" : "Show More "}
                    {showMore ? <ArrowUpward /> : <ArrowDownwardIcon />}
                  </span>
                </div>
              )}
          </Collapse>
        </div>

        {!isMobile && (
          <div className={`shipping-card-right ${styles.limitCartTotals}`}>
            <Stack className={styles.stackContainer}>
              {cart.type === CART_TYPE_SAMPLE && (
                <CartDiscountForm disabled={false} />
              )}
              <CartTotals cart={cart} step="shipping" />
            </Stack>
          </div>
        )}
      </Card>

      {!isMobile && (
        <div className="checkout__actions">
          <div className="checkout__breadcrumbs">
            <CheckoutFooter backLink="/cart" activeStep={1} />
          </div>

          <div className="checkout-button-container">
            <Button
              disabled={
                !shippingValid() ||
                cartHook.cart.status === "loading" ||
                status === "loading"
              }
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="button button--primary"
              loading={
                cartHook.cart.status === "loading" || status === "loading"
              }
            >
              Continue To Payment
            </Button>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="shipping-card-right">
          <Stack className={styles.stackContainer}>
            {cart.type === CART_TYPE_SAMPLE && (
              <CartDiscountForm disabled={false} />
            )}
            <CartTotals cart={cart} step="shipping" />
          </Stack>

          <div className="mt-10">
            <Button
              disabled={
                !shippingValid() ||
                cartHook.cart.status === "loading" ||
                status === "loading"
              }
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="button button--primary"
              fullWidth={isMobile}
            >
              Continue To Payment
            </Button>
          </div>
        </div>
      )}
      <ConfirmRemoveAddressModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={handleOnClickRemoveAddress}
        address={addresses?.find((address) => address.id === selectedAddressId)}
      />
    </div>
  );
}

CheckoutShippingView.propTypes = {
  // addShipping: PropTypes.func,
};

CheckoutShippingView.defaultProps = {
  // addShipping: () => {},
};

export default CheckoutShippingView;
